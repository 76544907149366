.container {
    width: 100%;
}
@media (min-width: 0px) {
    .container {
        max-width: 0px;
    }
}
@media (min-width: 375px) {
    .container {
        max-width: 375px;
    }
}
@media (min-width: 699px) {
    .container {
        max-width: 699px;
    }
}
@media (min-width: 700px) {
    .container {
        max-width: 700px;
    }
}
@media (min-width: 800px) {
    .container {
        max-width: 800px;
    }
}
@media (min-width: 959px) {
    .container {
        max-width: 959px;
    }
}
@media (min-width: 960px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}
@media (min-width: 1284px) {
    .container {
        max-width: 1284px;
    }
}
@media (min-width: 1285px) {
    .container {
        max-width: 1285px;
    }
}
@media (min-width: 1440px) {
    .container {
        max-width: 1440px;
    }
}
@media (min-width: 1599px) {
    .container {
        max-width: 1599px;
    }
}
@media (min-width: 1600px) {
    .container {
        max-width: 1600px;
    }
}
@media (min-width: 1919px) {
    .container {
        max-width: 1919px;
    }
}
@media (min-width: 1920px) {
    .container {
        max-width: 1920px;
    }
}
.aspect-h-1 {
    --tw-aspect-h: 1;
}
.aspect-h-3 {
    --tw-aspect-h: 3;
}
.aspect-h-4 {
    --tw-aspect-h: 4;
}
.aspect-h-5 {
    --tw-aspect-h: 5;
}
.aspect-h-6 {
    --tw-aspect-h: 6;
}
.aspect-h-7 {
    --tw-aspect-h: 7;
}
.aspect-h-8 {
    --tw-aspect-h: 8;
}
.aspect-h-9 {
    --tw-aspect-h: 9;
}
.aspect-w-1 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 1;
}
.aspect-w-1 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.aspect-w-16 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 16;
}
.aspect-w-16 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.aspect-w-2 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 2;
}
.aspect-w-2 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.aspect-w-3 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 3;
}
.aspect-w-3 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.aspect-w-4 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 4;
}
.aspect-w-4 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.aspect-w-23 {
    position: relative;
    --tw-aspect-w: 23;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
}
.aspect-w-23 > * {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
  .storybook-story-caption {
    font-family: Hind, Arial, sans-serif;
    color: var(--dt-theme-text-text-100);
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
.pointer-events-none {
    pointer-events: none;
}
.visible {
    visibility: visible;
}
.invisible {
    visibility: hidden;
}
.fixed {
    position: fixed;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.inset-0 {
    inset: 0;
}
.inset-x-0 {
    left: 0;
    right: 0;
}
.inset-y-0 {
    top: 0;
    bottom: 0;
}
.bottom-0 {
    bottom: 0;
}
.bottom-8 {
    bottom: 0.5rem;
}
.left-0 {
    left: 0;
}
.left-1\/2 {
    left: 50%;
}
.left-2 {
    left: 0.125rem;
}
.left-auto {
    left: auto;
}
.right-0 {
    right: 0;
}
.right-8 {
    right: 0.5rem;
}
.top-0 {
    top: 0;
}
.top-2 {
    top: 0.125rem;
}
.top-36 {
    top: 2.25rem;
}
.top-8 {
    top: 0.5rem;
}
.top-auto {
    top: auto;
}
.z-0 {
    z-index: 0;
}
.z-1 {
    z-index: 1;
}
.z-10 {
    z-index: 10;
}
.z-20 {
    z-index: 20;
}
.z-40 {
    z-index: 40;
}
.z-50 {
    z-index: 50;
}
.z-\[1001\] {
    z-index: 1001;
}
.z-\[2000\] {
    z-index: 2000;
}
.z-\[500\] {
    z-index: 500;
}
.order-1 {
    order: 1;
}
.order-2 {
    order: 2;
}
.order-3 {
    order: 3;
}
.float-end {
    float: inline-end;
}
.-m-dt-spacing-50 {
    margin: -0.25rem;
}
.m-0 {
    margin: 0;
}
.m-dt-spacing-50 {
    margin: 0.25rem;
}
.m-dt-spacing-none {
    margin: 0;
}
.\!mx-dt-spacing-none {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.-mx-16 {
    margin-left: -1rem;
    margin-right: -1rem;
}
.-my-dt-spacing-50 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
}
.mx-dt-spacing-200 {
    margin-left: 1rem;
    margin-right: 1rem;
}
.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}
.my-dt-spacing-100 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.my-dt-spacing-75 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
}
.my-dt-spacing-none {
    margin-top: 0;
    margin-bottom: 0;
}
.-mb-dt-spacing-600 {
    margin-bottom: -3rem;
}
.-mb-dt-spacing-800 {
    margin-bottom: -4rem;
}
.-ml-dt-spacing-50 {
    margin-left: -0.25rem;
}
.mb-16 {
    margin-bottom: 1rem;
}
.mb-24 {
    margin-bottom: 1.5rem;
}
.mb-dt-spacing-100 {
    margin-bottom: 0.5rem;
}
.mb-dt-spacing-150 {
    margin-bottom: 0.75rem;
}
.mb-dt-spacing-200 {
    margin-bottom: 1rem;
}
.mb-dt-spacing-25 {
    margin-bottom: 0.125rem;
}
.mb-dt-spacing-250 {
    margin-bottom: 1.25rem;
}
.mb-dt-spacing-300 {
    margin-bottom: 1.5rem;
}
.mb-dt-spacing-none {
    margin-bottom: 0;
}
.ml-auto {
    margin-left: auto;
}
.ml-dt-spacing-100 {
    margin-left: 0.5rem;
}
.ml-dt-spacing-150 {
    margin-left: 0.75rem;
}
.ml-dt-spacing-200 {
    margin-left: 1rem;
}
.ml-dt-spacing-25 {
    margin-left: 0.125rem;
}
.ml-dt-spacing-300 {
    margin-left: 1.5rem;
}
.ml-dt-spacing-400 {
    margin-left: 2rem;
}
.ml-dt-spacing-50 {
    margin-left: 0.25rem;
}
.ml-dt-spacing-none {
    margin-left: 0;
}
.mr-dt-spacing-100 {
    margin-right: 0.5rem;
}
.mr-dt-spacing-125 {
    margin-right: 0.625rem;
}
.mr-dt-spacing-200 {
    margin-right: 1rem;
}
.mr-dt-spacing-25 {
    margin-right: 0.125rem;
}
.mr-dt-spacing-300 {
    margin-right: 1.5rem;
}
.ms-dt-spacing-100 {
    margin-inline-start: 0.5rem;
}
.mt-auto {
    margin-top: auto;
}
.mt-dt-spacing-100 {
    margin-top: 0.5rem;
}
.mt-dt-spacing-125 {
    margin-top: 0.625rem;
}
.mt-dt-spacing-150 {
    margin-top: 0.75rem;
}
.mt-dt-spacing-200 {
    margin-top: 1rem;
}
.mt-dt-spacing-25 {
    margin-top: 0.125rem;
}
.mt-dt-spacing-300 {
    margin-top: 1.5rem;
}
.mt-dt-spacing-50 {
    margin-top: 0.25rem;
}
.mt-dt-spacing-75 {
    margin-top: 0.375rem;
}
.mt-dt-spacing-none {
    margin-top: 0;
}
.box-border {
    box-sizing: border-box;
}
.line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.line-clamp-4 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}
.line-clamp-6 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
}
.block {
    display: block;
}
.inline-block {
    display: inline-block;
}
.inline {
    display: inline;
}
.flex {
    display: flex;
}
.inline-flex {
    display: inline-flex;
}
.table {
    display: table;
}
.grid {
    display: grid;
}
.hidden {
    display: none;
}
.size-16 {
    width: 1rem;
    height: 1rem;
}
.size-18 {
    width: 1.125rem;
    height: 1.125rem;
}
.size-20 {
    width: 1.25rem;
    height: 1.25rem;
}
.size-22 {
    width: 1.375rem;
    height: 1.375rem;
}
.size-24 {
    width: 1.5rem;
    height: 1.5rem;
}
.size-28 {
    width: 1.75rem;
    height: 1.75rem;
}
.size-32 {
    width: 2rem;
    height: 2rem;
}
.size-38 {
    width: 2.375rem;
    height: 2.375rem;
}
.size-40 {
    width: 2.5rem;
    height: 2.5rem;
}
.size-50 {
    width: 3.125rem;
    height: 3.125rem;
}
.size-54 {
    width: 3.375rem;
    height: 3.375rem;
}
.size-\[2\.3375rem\] {
    width: 2.3375rem;
    height: 2.3375rem;
}
.size-\[6\.25rem\] {
    width: 6.25rem;
    height: 6.25rem;
}
.size-auto {
    width: auto;
    height: auto;
}
.size-fit {
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.size-full {
    width: 100%;
    height: 100%;
}
.\!h-20 {
    height: 1.25rem !important;
}
.\!h-28 {
    height: 1.75rem !important;
}
.\!h-48 {
    height: 3rem !important;
}
.h-1\/2 {
    height: 50%;
}
.h-15 {
    height: 0.9375rem;
}
.h-16 {
    height: 1rem;
}
.h-18 {
    height: 1.125rem;
}
.h-2 {
    height: 0.125rem;
}
.h-20 {
    height: 1.25rem;
}
.h-22 {
    height: 1.375rem;
}
.h-24 {
    height: 1.5rem;
}
.h-26 {
    height: 1.625rem;
}
.h-28 {
    height: 1.75rem;
}
.h-30 {
    height: 1.875rem;
}
.h-32 {
    height: 2rem;
}
.h-38 {
    height: 2.375rem;
}
.h-4 {
    height: 0.25rem;
}
.h-40 {
    height: 2.5rem;
}
.h-44 {
    height: 2.75rem;
}
.h-46 {
    height: 2.875rem;
}
.h-48 {
    height: 3rem;
}
.h-50 {
    height: 3.125rem;
}
.h-52 {
    height: 3.25rem;
}
.h-54 {
    height: 3.375rem;
}
.h-56 {
    height: 3.5rem;
}
.h-76 {
    height: 4.75rem;
}
.h-8 {
    height: 0.5rem;
}
.h-\[12\.5rem\] {
    height: 12.5rem;
}
.h-\[16\.875rem\] {
    height: 16.875rem;
}
.h-\[9\.875rem\] {
    height: 9.875rem;
}
.h-\[90vh\] {
    height: 90vh;
}
.h-\[calc\(100vh-3rem\)\] {
    height: calc(100vh - 3rem);
}
.h-\[calc\(100vh-6rem\)\] {
    height: calc(100vh - 6rem);
}
.h-\[inherit\] {
    height: inherit;
}
.h-auto {
    height: auto;
}
.h-fit {
    height: -moz-fit-content;
    height: fit-content;
}
.h-full {
    height: 100%;
}
.max-h-20 {
    max-height: 1.25rem;
}
.max-h-full {
    max-height: 100%;
}
.min-h-20 {
    min-height: 1.25rem;
}
.min-h-32 {
    min-height: 2rem;
}
.min-h-4 {
    min-height: 0.25rem;
}
.min-h-52 {
    min-height: 3.25rem;
}
.min-h-64 {
    min-height: 4rem;
}
.min-h-8 {
    min-height: 0.5rem;
}
.min-h-88 {
    min-height: 5.5rem;
}
.min-h-\[18\.75rem\] {
    min-height: 18.75rem;
}
.min-h-\[inherit\] {
    min-height: inherit;
}
.w-0 {
    width: 0;
}
.w-1\/2 {
    width: 50%;
}
.w-1\/4 {
    width: 25%;
}
.w-15 {
    width: 0.9375rem;
}
.w-16 {
    width: 1rem;
}
.w-2\/3 {
    width: 66.666667%;
}
.w-20 {
    width: 1.25rem;
}
.w-24 {
    width: 1.5rem;
}
.w-38 {
    width: 2.375rem;
}
.w-40 {
    width: 2.5rem;
}
.w-56 {
    width: 3.5rem;
}
.w-72 {
    width: 4.5rem;
}
.w-80 {
    width: 5rem;
}
.w-88 {
    width: 5.5rem;
}
.w-\[10rem\] {
    width: 10rem;
}
.w-\[11\.5rem\] {
    width: 11.5rem;
}
.w-\[12\.5rem\] {
    width: 12.5rem;
}
.w-\[15\.625rem\] {
    width: 15.625rem;
}
.w-\[18\.75rem\] {
    width: 18.75rem;
}
.w-\[21\.875rem\] {
    width: 21.875rem;
}
.w-\[25\.6875rem\] {
    width: 25.6875rem;
}
.w-\[30rem\] {
    width: 30rem;
}
.w-\[4\.3125rem\] {
    width: 4.3125rem;
}
.w-\[51rem\] {
    width: 51rem;
}
.w-\[7\.3125rem\] {
    width: 7.3125rem;
}
.w-\[7\.5rem\] {
    width: 7.5rem;
}
.w-\[9rem\] {
    width: 9rem;
}
.w-\[inherit\] {
    width: inherit;
}
.w-auto {
    width: auto;
}
.w-fit {
    width: -moz-fit-content;
    width: fit-content;
}
.w-full {
    width: 100%;
}
.w-max {
    width: -moz-max-content;
    width: max-content;
}
.w-min {
    width: -moz-min-content;
    width: min-content;
}
.min-w-0 {
    min-width: 0;
}
.min-w-20 {
    min-width: 1.25rem;
}
.min-w-22 {
    min-width: 1.375rem;
}
.min-w-24 {
    min-width: 1.5rem;
}
.min-w-26 {
    min-width: 1.625rem;
}
.min-w-32 {
    min-width: 2rem;
}
.min-w-38 {
    min-width: 2.375rem;
}
.min-w-40 {
    min-width: 2.5rem;
}
.min-w-43 {
    min-width: 2.6875rem;
}
.min-w-48 {
    min-width: 3rem;
}
.min-w-50 {
    min-width: 3.125rem;
}
.min-w-54 {
    min-width: 3.375rem;
}
.min-w-60 {
    min-width: 3.75rem;
}
.min-w-80 {
    min-width: 5rem;
}
.min-w-\[14rem\] {
    min-width: 14rem;
}
.min-w-\[17\.4375rem\] {
    min-width: 17.4375rem;
}
.min-w-\[18rem\] {
    min-width: 18rem;
}
.min-w-\[25rem\] {
    min-width: 25rem;
}
.min-w-\[30rem\] {
    min-width: 30rem;
}
.min-w-\[7\.25rem\] {
    min-width: 7.25rem;
}
.min-w-fit {
    min-width: -moz-fit-content;
    min-width: fit-content;
}
.min-w-full {
    min-width: 100%;
}
.max-w-\[12\.5rem\] {
    max-width: 12.5rem;
}
.max-w-\[21rem\] {
    max-width: 21rem;
}
.max-w-\[30rem\] {
    max-width: 30rem;
}
.max-w-\[40rem\] {
    max-width: 40rem;
}
.max-w-fit {
    max-width: -moz-fit-content;
    max-width: fit-content;
}
.max-w-full {
    max-width: 100%;
}
.max-w-min {
    max-width: -moz-min-content;
    max-width: min-content;
}
.flex-1 {
    flex: 1 1 0%;
}
.flex-auto {
    flex: 1 1 auto;
}
.flex-none {
    flex: none;
}
.\!shrink {
    flex-shrink: 1 !important;
}
.shrink-0 {
    flex-shrink: 0;
}
.grow-0 {
    flex-grow: 0;
}
.\!basis-20 {
    flex-basis: 1.25rem !important;
}
.basis-24 {
    flex-basis: 1.5rem;
}
.basis-26 {
    flex-basis: 1.625rem;
}
.basis-32 {
    flex-basis: 2rem;
}
.basis-38 {
    flex-basis: 2.375rem;
}
.basis-40 {
    flex-basis: 2.5rem;
}
.origin-center {
    transform-origin: center;
}
.translate-x-0 {
    --tw-translate-x: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-20 {
    --tw-translate-x: 1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-0 {
    --tw-translate-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-0 {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.skew-x-0 {
    --tw-skew-x: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.skew-y-0 {
    --tw-skew-y: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-avatar-icon {
    --tw-scale-x: 0.8;
    --tw-scale-y: 0.8;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-x-100 {
    --tw-scale-x: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-y-100 {
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.animate-fade-in {
    animation: fade-in 0.3s ease-in none;
}
.cursor-default {
    cursor: default;
}
.cursor-not-allowed {
    cursor: not-allowed;
}
.cursor-pointer {
    cursor: pointer;
}
.select-none {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}
.resize {
    resize: both;
}
.snap-x {
    scroll-snap-type: x var(--tw-scroll-snap-strictness);
}
.scroll-px-16 {
    scroll-padding-left: 1rem;
    scroll-padding-right: 1rem;
}
.list-none {
    list-style-type: none;
}
.appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}
.flex-row {
    flex-direction: row;
}
.flex-row-reverse {
    flex-direction: row-reverse;
}
.flex-col {
    flex-direction: column;
}
.flex-wrap {
    flex-wrap: wrap;
}
.content-start {
    align-content: flex-start;
}
.content-end {
    align-content: flex-end;
}
.items-start {
    align-items: flex-start;
}
.items-end {
    align-items: flex-end;
}
.items-center {
    align-items: center;
}
.items-baseline {
    align-items: baseline;
}
.items-stretch {
    align-items: stretch;
}
.justify-start {
    justify-content: flex-start;
}
.justify-end {
    justify-content: flex-end;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.gap-16 {
    gap: 1rem;
}
.gap-2 {
    gap: 0.125rem;
}
.gap-24 {
    gap: 1.5rem;
}
.gap-32 {
    gap: 2rem;
}
.gap-4 {
    gap: 0.25rem;
}
.gap-6 {
    gap: 0.375rem;
}
.gap-8 {
    gap: 0.5rem;
}
.gap-x-12 {
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem;
}
.gap-y-4 {
    row-gap: 0.25rem;
}
.-space-x-dt-spacing-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-dt-spacing-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-dt-spacing-150 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-dt-spacing-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-dt-spacing-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-dt-spacing-250 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-dt-spacing-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-dt-spacing-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-dt-spacing-none > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.space-y-dt-spacing-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.space-y-dt-spacing-150 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
.space-y-dt-spacing-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.space-y-dt-spacing-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.space-y-dt-spacing-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.space-y-dt-spacing-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}
.space-y-dt-spacing-none > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
}
.space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
}
.divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
.divide-\[--dt-theme-border-accordion-accordion-divider\] > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--dt-theme-border-accordion-accordion-divider);
}
.self-start {
    align-self: flex-start;
}
.self-end {
    align-self: flex-end;
}
.self-center {
    align-self: center;
}
.justify-self-end {
    justify-self: end;
}
.overflow-auto {
    overflow: auto;
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-x-auto {
    overflow-x: auto;
}
.overflow-y-auto {
    overflow-y: auto;
}
.overflow-y-hidden {
    overflow-y: hidden;
}
.scroll-smooth {
    scroll-behavior: smooth;
}
.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.text-ellipsis {
    text-overflow: ellipsis;
}
.whitespace-nowrap {
    white-space: nowrap;
}
.rounded {
    border-radius: 0.25rem;
}
.rounded-dt-radius-circle {
    border-radius: 6.25rem;
}
.rounded-dt-radius-large {
    border-radius: 0.375rem;
}
.rounded-dt-radius-medium {
    border-radius: 0.25rem;
}
.rounded-dt-radius-none {
    border-radius: 0;
}
.rounded-dt-radius-small {
    border-radius: 0.125rem;
}
.rounded-dt-radius-xlarge {
    border-radius: 0.5rem;
}
.rounded-full {
    border-radius: 9999px;
}
.rounded-md {
    border-radius: 0.375rem;
}
.rounded-b-dt-radius-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.rounded-b-dt-radius-small {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
}
.rounded-t-dt-radius-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.rounded-t-dt-radius-small {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
}
.rounded-bl-dt-radius-medium {
    border-bottom-left-radius: 0.25rem;
}
.rounded-bl-dt-radius-small {
    border-bottom-left-radius: 0.125rem;
}
.rounded-br-dt-radius-medium {
    border-bottom-right-radius: 0.25rem;
}
.rounded-br-dt-radius-small {
    border-bottom-right-radius: 0.125rem;
}
.rounded-tl-dt-radius-none {
    border-top-left-radius: 0;
}
.rounded-tl-dt-radius-small {
    border-top-left-radius: 0.125rem;
}
.rounded-tr-dt-radius-none {
    border-top-right-radius: 0;
}
.rounded-tr-dt-radius-small {
    border-top-right-radius: 0.125rem;
}
.border {
    border-width: 1px;
}
.border-dt-border-none {
    border-width: 0;
}
.border-dt-border-regular {
    border-width: 0.125rem;
}
.border-dt-border-thin {
    border-width: 0.0625rem;
}
.border-dt-border-ultra-strong {
    border-width: 0.5rem;
}
.border-dt-border-ultra-thin {
    border-width: 0.03125rem;
}
.border-b {
    border-bottom-width: 1px;
}
.border-b-dt-border-none {
    border-bottom-width: 0;
}
.border-b-dt-border-thin {
    border-bottom-width: 0.0625rem;
}
.border-t-dt-border-none {
    border-top-width: 0;
}
.border-solid {
    border-style: solid;
}
.border-dashed {
    border-style: dashed;
}
.border-none {
    border-style: none;
}
.border-dt-theme-border-accordion-accordion-divider {
    border-color: var(--dt-theme-border-accordion-accordion-divider);
}
.border-dt-theme-border-border {
    border-color: var(--dt-theme-border-border);
}
.border-dt-theme-border-border-active {
    border-color: var(--dt-theme-border-border-active);
}
.border-dt-theme-border-border-opaque {
    border-color: var(--dt-theme-border-border-opaque);
}
.border-dt-theme-border-button-icon-secondary-button-icon-secondary {
    border-color: var(--dt-theme-border-button-icon-secondary-button-icon-secondary);
}
.border-dt-theme-border-checkbox-checkbox {
    border-color: var(--dt-theme-border-checkbox-checkbox);
}
.border-dt-theme-border-checkbox-checkbox-error {
    border-color: var(--dt-theme-border-checkbox-checkbox-error);
}
.border-dt-theme-border-list-ranking-list-ranking {
    border-color: var(--dt-theme-border-list-ranking-list-ranking);
}
.border-dt-theme-border-list-row-list-row {
    border-color: var(--dt-theme-border-list-row-list-row);
}
.border-dt-theme-border-list-statistics-list-statistics {
    border-color: var(--dt-theme-border-list-statistics-list-statistics);
}
.border-dt-theme-border-list-timeline-vote-list-timeline-vote {
    border-color: var(--dt-theme-border-list-timeline-vote-list-timeline-vote);
}
.border-dt-theme-border-number-access-input-field-number-access-input-field-default {
    border-color: var(--dt-theme-border-number-access-input-field-number-access-input-field-default);
}
.border-dt-theme-border-number-access-input-field-number-access-input-field-error-default {
    border-color: var(--dt-theme-border-number-access-input-field-number-access-input-field-error-default);
}
.border-dt-theme-border-offer-tag-offer-tag {
    border-color: var(--dt-theme-border-offer-tag-offer-tag);
}
.border-dt-theme-border-password-input-field-password-input-field-default {
    border-color: var(--dt-theme-border-password-input-field-password-input-field-default);
}
.border-dt-theme-border-password-input-field-password-input-field-error-default {
    border-color: var(--dt-theme-border-password-input-field-password-input-field-error-default);
}
.border-dt-theme-border-profile-card-divider {
    border-color: var(--dt-theme-border-profile-card-divider);
}
.border-dt-theme-border-radio-button-field-radio-button-field {
    border-color: var(--dt-theme-border-radio-button-field-radio-button-field);
}
.border-dt-theme-border-text-input-field-text-input-field-default {
    border-color: var(--dt-theme-border-text-input-field-text-input-field-default);
}
.border-dt-theme-border-text-input-field-text-input-field-error-default {
    border-color: var(--dt-theme-border-text-input-field-text-input-field-error-default);
}
.border-dt-theme-border-text-input-field-text-input-field-success-default {
    border-color: var(--dt-theme-border-text-input-field-text-input-field-success-default);
}
.border-dt-theme-border-text-input-field-text-input-field-warning-default {
    border-color: var(--dt-theme-border-text-input-field-text-input-field-warning-default);
}
.border-dt-theme-text-text-100 {
    border-color: var(--dt-theme-text-text-100);
}
.border-dt-theme-tv-border-list-list {
    border-color: var(--dt-theme-tv-border-list-list);
}
.border-dt-theme-tv-border-offer-tag-offer-tag {
    border-color: var(--dt-theme-tv-border-offer-tag-offer-tag);
}
.border-dt-theme-tv-surface-list-list {
    border-color: var(--dt-theme-tv-surface-list-list);
}
.border-dt-theme-tv-text-alert-banner-alert-banner {
    border-color: var(--dt-theme-tv-text-alert-banner-alert-banner);
}
.border-transparent {
    border-color: rgba(0, 0, 0, 0);
}
.border-b-dt-theme-border-header-header {
    border-bottom-color: var(--dt-theme-border-header-header);
}
.bg-\[\#986D23\] {
    --tw-bg-opacity: 1;
    background-color: rgb(152 109 35 / var(--tw-bg-opacity, 1));
}
.bg-\[\#b2b2b2\] {
    --tw-bg-opacity: 1;
    background-color: rgb(178 178 178 / var(--tw-bg-opacity, 1));
}
.bg-\[--dt-global-palette-white-100\] {
    background-color: var(--dt-global-palette-white-100);
}
.bg-dt-theme-background-avatar-avatar {
    background-color: var(--dt-theme-background-avatar-avatar);
}
.bg-dt-theme-background-avatar-avatar-profile {
    background-color: var(--dt-theme-background-avatar-avatar-profile);
}
.bg-dt-theme-background-button-icon-button-icon-primary {
    background-color: var(--dt-theme-background-button-icon-button-icon-primary);
}
.bg-dt-theme-background-button-icon-video-button-icon-video {
    background-color: var(--dt-theme-background-button-icon-video-button-icon-video);
}
.bg-dt-theme-background-button-logo-button-logo {
    background-color: var(--dt-theme-background-button-logo-button-logo);
}
.bg-dt-theme-background-button-primary-accent {
    background-color: var(--dt-theme-background-button-primary-accent);
}
.bg-dt-theme-background-button-secondary-secondary {
    background-color: var(--dt-theme-background-button-secondary-secondary);
}
.bg-dt-theme-background-episode-list-episode-list-unavailable {
    background-color: var(--dt-theme-background-episode-list-episode-list-unavailable);
}
.bg-dt-theme-background-header-header {
    background-color: var(--dt-theme-background-header-header);
}
.bg-dt-theme-background-icon-play-button {
    background-color: var(--dt-theme-background-icon-play-button);
}
.bg-dt-theme-background-list-timeline-vote-list-timeline-vote-default {
    background-color: var(--dt-theme-background-list-timeline-vote-list-timeline-vote-default);
}
.bg-dt-theme-background-list-timeline-vote-list-timeline-vote-success {
    background-color: var(--dt-theme-background-list-timeline-vote-list-timeline-vote-success);
}
.bg-dt-theme-background-profile-card-profile-card {
    background-color: var(--dt-theme-background-profile-card-profile-card);
}
.bg-dt-theme-background-progressbar-progressbar {
    background-color: var(--dt-theme-background-progressbar-progressbar);
}
.bg-dt-theme-background-progressbar-progressbar-forced-dark {
    background-color: var(--dt-theme-background-progressbar-progressbar-forced-dark);
}
.bg-dt-theme-background-toggle-switch-toggle-switch-off {
    background-color: var(--dt-theme-background-toggle-switch-toggle-switch-off);
}
.bg-dt-theme-background-toggle-switch-toggle-switch-on {
    background-color: var(--dt-theme-background-toggle-switch-toggle-switch-on);
}
.bg-dt-theme-brand-primary-accent {
    background-color: var(--dt-theme-brand-primary-accent);
}
.bg-dt-theme-brand-secondary-cinema {
    background-color: var(--dt-theme-brand-secondary-cinema);
}
.bg-dt-theme-icon-icon {
    background-color: var(--dt-theme-icon-icon);
}
.bg-dt-theme-surface-accordion-accordion {
    background-color: var(--dt-theme-surface-accordion-accordion);
}
.bg-dt-theme-surface-alert-box-alert-box {
    background-color: var(--dt-theme-surface-alert-box-alert-box);
}
.bg-dt-theme-surface-alert-box-alert-box-error {
    background-color: var(--dt-theme-surface-alert-box-alert-box-error);
}
.bg-dt-theme-surface-alert-box-alert-box-info {
    background-color: var(--dt-theme-surface-alert-box-alert-box-info);
}
.bg-dt-theme-surface-alert-box-alert-box-success {
    background-color: var(--dt-theme-surface-alert-box-alert-box-success);
}
.bg-dt-theme-surface-alert-box-alert-box-warning {
    background-color: var(--dt-theme-surface-alert-box-alert-box-warning);
}
.bg-dt-theme-surface-card-tab-card-tab-hover {
    background-color: var(--dt-theme-surface-card-tab-card-tab-hover);
}
.bg-dt-theme-surface-card-timeline-facts-card-timeline-facts {
    background-color: var(--dt-theme-surface-card-timeline-facts-card-timeline-facts);
}
.bg-dt-theme-surface-checkbox-checkbox {
    background-color: var(--dt-theme-surface-checkbox-checkbox);
}
.bg-dt-theme-surface-input-field-alert-input-field-alert {
    background-color: var(--dt-theme-surface-input-field-alert-input-field-alert);
}
.bg-dt-theme-surface-input-field-alert-input-field-alert-error {
    background-color: var(--dt-theme-surface-input-field-alert-input-field-alert-error);
}
.bg-dt-theme-surface-input-field-alert-input-field-alert-info {
    background-color: var(--dt-theme-surface-input-field-alert-input-field-alert-info);
}
.bg-dt-theme-surface-input-field-alert-input-field-alert-success {
    background-color: var(--dt-theme-surface-input-field-alert-input-field-alert-success);
}
.bg-dt-theme-surface-input-field-alert-input-field-alert-warning {
    background-color: var(--dt-theme-surface-input-field-alert-input-field-alert-warning);
}
.bg-dt-theme-surface-list-ranking-list-ranking {
    background-color: var(--dt-theme-surface-list-ranking-list-ranking);
}
.bg-dt-theme-surface-list-ranking-list-ranking-highlight {
    background-color: var(--dt-theme-surface-list-ranking-list-ranking-highlight);
}
.bg-dt-theme-surface-list-row-list-row {
    background-color: var(--dt-theme-surface-list-row-list-row);
}
.bg-dt-theme-surface-list-row-list-row-disabled {
    background-color: var(--dt-theme-surface-list-row-list-row-disabled);
}
.bg-dt-theme-surface-list-statistics-list-statistics {
    background-color: var(--dt-theme-surface-list-statistics-list-statistics);
}
.bg-dt-theme-surface-number-access-input-field-number-access-input-field {
    background-color: var(--dt-theme-surface-number-access-input-field-number-access-input-field);
}
.bg-dt-theme-surface-password-input-field-password-input-field {
    background-color: var(--dt-theme-surface-password-input-field-password-input-field);
}
.bg-dt-theme-surface-profile-card-profile-card {
    background-color: var(--dt-theme-surface-profile-card-profile-card);
}
.bg-dt-theme-surface-profile-card-profile-card-back {
    background-color: var(--dt-theme-surface-profile-card-profile-card-back);
}
.bg-dt-theme-surface-progressbar-progressbar-live {
    background-color: var(--dt-theme-surface-progressbar-progressbar-live);
}
.bg-dt-theme-surface-progressbar-progressbar-on-going {
    background-color: var(--dt-theme-surface-progressbar-progressbar-on-going);
}
.bg-dt-theme-surface-radio-button-field-radio-button-field {
    background-color: var(--dt-theme-surface-radio-button-field-radio-button-field);
}
.bg-dt-theme-surface-season-selector-season-selector-selected {
    background-color: var(--dt-theme-surface-season-selector-season-selector-selected);
}
.bg-dt-theme-surface-season-selector-season-selector-unselected {
    background-color: var(--dt-theme-surface-season-selector-season-selector-unselected);
}
.bg-dt-theme-surface-sticker-sticker-accent {
    background-color: var(--dt-theme-surface-sticker-sticker-accent);
}
.bg-dt-theme-surface-sticker-sticker-emphasis {
    background-color: var(--dt-theme-surface-sticker-sticker-emphasis);
}
.bg-dt-theme-surface-sticker-sticker-primary {
    background-color: var(--dt-theme-surface-sticker-sticker-primary);
}
.bg-dt-theme-surface-sticker-sticker-secondary {
    background-color: var(--dt-theme-surface-sticker-sticker-secondary);
}
.bg-dt-theme-surface-surface {
    background-color: var(--dt-theme-surface-surface);
}
.bg-dt-theme-surface-text-input-field-text-input-field {
    background-color: var(--dt-theme-surface-text-input-field-text-input-field);
}
.bg-dt-theme-surface-toggle-switch-toggle {
    background-color: var(--dt-theme-surface-toggle-switch-toggle);
}
.bg-dt-theme-surface-tooltip-tooltip {
    background-color: var(--dt-theme-surface-tooltip-tooltip);
}
.bg-dt-theme-text-text-100 {
    background-color: var(--dt-theme-text-text-100);
}
.bg-dt-theme-tv-background-alert-banner-alert-banner {
    background-color: var(--dt-theme-tv-background-alert-banner-alert-banner);
}
.bg-dt-theme-tv-background-avatar-avatar {
    background-color: var(--dt-theme-tv-background-avatar-avatar);
}
.bg-dt-theme-tv-background-button-button-default {
    background-color: var(--dt-theme-tv-background-button-button-default);
}
.bg-dt-theme-tv-background-button-icon-button-icon-default {
    background-color: var(--dt-theme-tv-background-button-icon-button-icon-default);
}
.bg-dt-theme-tv-background-button-logo-button-logo {
    background-color: var(--dt-theme-tv-background-button-logo-button-logo);
}
.bg-dt-theme-tv-background-list-timeline-vote-list-timeline-vote-default {
    background-color: var(--dt-theme-tv-background-list-timeline-vote-list-timeline-vote-default);
}
.bg-dt-theme-tv-background-list-timeline-vote-list-timeline-vote-error {
    background-color: var(--dt-theme-tv-background-list-timeline-vote-list-timeline-vote-error);
}
.bg-dt-theme-tv-background-list-timeline-vote-list-timeline-vote-success {
    background-color: var(--dt-theme-tv-background-list-timeline-vote-list-timeline-vote-success);
}
.bg-dt-theme-tv-background-season-selector-season-selector-selected {
    background-color: var(--dt-theme-tv-background-season-selector-season-selector-selected);
}
.bg-dt-theme-tv-surface-alert-alert {
    background-color: var(--dt-theme-tv-surface-alert-alert);
}
.bg-dt-theme-tv-surface-alert-banner-alert-banner {
    background-color: var(--dt-theme-tv-surface-alert-banner-alert-banner);
}
.bg-dt-theme-tv-surface-card-timeline-facts-card-timeline-facts {
    background-color: var(--dt-theme-tv-surface-card-timeline-facts-card-timeline-facts);
}
.bg-dt-theme-tv-surface-list-list {
    background-color: var(--dt-theme-tv-surface-list-list);
}
.bg-dt-theme-tv-surface-list-ranking-list-ranking {
    background-color: var(--dt-theme-tv-surface-list-ranking-list-ranking);
}
.bg-dt-theme-tv-surface-list-ranking-list-ranking-highlight {
    background-color: var(--dt-theme-tv-surface-list-ranking-list-ranking-highlight);
}
.bg-dt-theme-tv-surface-list-statistics-list-statistics {
    background-color: var(--dt-theme-tv-surface-list-statistics-list-statistics);
}
.bg-dt-theme-tv-surface-progressbar-progressbar-background {
    background-color: var(--dt-theme-tv-surface-progressbar-progressbar-background);
}
.bg-dt-theme-tv-surface-progressbar-progressbar-live {
    background-color: var(--dt-theme-tv-surface-progressbar-progressbar-live);
}
.bg-dt-theme-tv-surface-progressbar-progressbar-on-going {
    background-color: var(--dt-theme-tv-surface-progressbar-progressbar-on-going);
}
.bg-dt-theme-tv-surface-sticker-sticker-accent {
    background-color: var(--dt-theme-tv-surface-sticker-sticker-accent);
}
.bg-dt-theme-tv-surface-sticker-sticker-emphasis {
    background-color: var(--dt-theme-tv-surface-sticker-sticker-emphasis);
}
.bg-dt-theme-tv-surface-sticker-sticker-primary {
    background-color: var(--dt-theme-tv-surface-sticker-sticker-primary);
}
.bg-dt-theme-tv-surface-sticker-sticker-secondary {
    background-color: var(--dt-theme-tv-surface-sticker-sticker-secondary);
}
.bg-transparent {
    background-color: rgba(0, 0, 0, 0);
}
.bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
}
.bg-list-statistics-gradient {
    background-image: linear-gradient(
          to right,
          var(--left-color) 0% var(--gap-percentage-start),
          transparent var(--gap-percentage-start) var(--gap-percentage-end),
          var(--right-color) var(--gap-percentage-end) 100%);
}
.bg-media-overlay-gradient-logo {
    background-image: radial-gradient(
          60% 90% at 0% 100%,
          var(--dt-theme-gradient-media-logo-gradient-gradient-start) 0%,
          var(--dt-theme-gradient-media-logo-gradient-gradient-end) 100%);
}
.bg-with-dark-background-decorator-gradient {
    background-image: linear-gradient(to bottom, var(--dt-global-palette-black-100), var(--dt-global-palette-black-opacity-30));
}
.from-dt-theme-gradient-media-overlay-gradient-gradient-start {
    --tw-gradient-from: var(--dt-theme-gradient-media-overlay-gradient-gradient-start) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.to-dt-theme-gradient-media-overlay-gradient-gradient-end {
    --tw-gradient-to: var(--dt-theme-gradient-media-overlay-gradient-gradient-end) var(--tw-gradient-to-position);
}
.\!fill-dt-theme-icon-alert-box-icon {
    fill: var(--dt-theme-icon-alert-box-icon) !important;
}
.fill-dt-theme-border-radio-button-radio-button {
    fill: var(--dt-theme-border-radio-button-radio-button);
}
.fill-dt-theme-border-radio-button-radio-button-error {
    fill: var(--dt-theme-border-radio-button-radio-button-error);
}
.fill-dt-theme-icon-avatar-icon {
    fill: var(--dt-theme-icon-avatar-icon);
}
.fill-dt-theme-icon-avatar-icon-profile {
    fill: var(--dt-theme-icon-avatar-icon-profile);
}
.fill-dt-theme-icon-button-icon-mood-icon-bad {
    fill: var(--dt-theme-icon-button-icon-mood-icon-bad);
}
.fill-dt-theme-icon-button-icon-mood-icon-good {
    fill: var(--dt-theme-icon-button-icon-mood-icon-good);
}
.fill-dt-theme-icon-button-icon-mood-icon-neutral {
    fill: var(--dt-theme-icon-button-icon-mood-icon-neutral);
}
.fill-dt-theme-icon-button-primary-icon {
    fill: var(--dt-theme-icon-button-primary-icon);
}
.fill-dt-theme-icon-button-secondary-icon {
    fill: var(--dt-theme-icon-button-secondary-icon);
}
.fill-dt-theme-icon-button-tertiary-icon {
    fill: var(--dt-theme-icon-button-tertiary-icon);
}
.fill-dt-theme-icon-button-text-icon-accent-default {
    fill: var(--dt-theme-icon-button-text-icon-accent-default);
}
.fill-dt-theme-icon-button-text-icon-accent-pressed {
    fill: var(--dt-theme-icon-button-text-icon-accent-pressed);
}
.fill-dt-theme-icon-checkbox-icon {
    fill: var(--dt-theme-icon-checkbox-icon);
}
.fill-dt-theme-icon-header-icon {
    fill: var(--dt-theme-icon-header-icon);
}
.fill-dt-theme-icon-header-icon-active {
    fill: var(--dt-theme-icon-header-icon-active);
}
.fill-dt-theme-icon-header-icon-disabled {
    fill: var(--dt-theme-icon-header-icon-disabled);
}
.fill-dt-theme-icon-link-link-icon {
    fill: var(--dt-theme-icon-link-link-icon);
}
.fill-dt-theme-icon-link-link-icon-accent {
    fill: var(--dt-theme-icon-link-link-icon-accent);
}
.fill-dt-theme-icon-list-row-check-icon-accent {
    fill: var(--dt-theme-icon-list-row-check-icon-accent);
}
.fill-dt-theme-icon-list-row-chevron {
    fill: var(--dt-theme-icon-list-row-chevron);
}
.fill-dt-theme-icon-list-row-chevron-disabled {
    fill: var(--dt-theme-icon-list-row-chevron-disabled);
}
.fill-dt-theme-icon-list-row-chevron-price {
    fill: var(--dt-theme-icon-list-row-chevron-price);
}
.fill-dt-theme-icon-list-row-chevron-price-disabled {
    fill: var(--dt-theme-icon-list-row-chevron-price-disabled);
}
.fill-dt-theme-icon-list-row-link-icon {
    fill: var(--dt-theme-icon-list-row-link-icon);
}
.fill-dt-theme-icon-password-input-field-icon {
    fill: var(--dt-theme-icon-password-input-field-icon);
}
.fill-dt-theme-icon-rating-icon-primary-selected {
    fill: var(--dt-theme-icon-rating-icon-primary-selected);
}
.fill-dt-theme-icon-rating-icon-primary-unselected {
    fill: var(--dt-theme-icon-rating-icon-primary-unselected);
}
.fill-dt-theme-icon-rating-icon-secondary-selected {
    fill: var(--dt-theme-icon-rating-icon-secondary-selected);
}
.fill-dt-theme-icon-rating-icon-secondary-unselected {
    fill: var(--dt-theme-icon-rating-icon-secondary-unselected);
}
.fill-dt-theme-icon-sticker-icon-accent {
    fill: var(--dt-theme-icon-sticker-icon-accent);
}
.fill-dt-theme-icon-sticker-icon-emphasis {
    fill: var(--dt-theme-icon-sticker-icon-emphasis);
}
.fill-dt-theme-icon-sticker-icon-primary {
    fill: var(--dt-theme-icon-sticker-icon-primary);
}
.fill-dt-theme-icon-sticker-icon-secondary {
    fill: var(--dt-theme-icon-sticker-icon-secondary);
}
.fill-dt-theme-icon-text-input-field-icon {
    fill: var(--dt-theme-icon-text-input-field-icon);
}
.fill-dt-theme-icon-tooltip-tooltip-link-icon {
    fill: var(--dt-theme-icon-tooltip-tooltip-link-icon);
}
.fill-dt-theme-surface-tooltip-tooltip {
    fill: var(--dt-theme-surface-tooltip-tooltip);
}
.fill-dt-theme-text-list-timeline-vote-list-timeline-vote-title {
    fill: var(--dt-theme-text-list-timeline-vote-list-timeline-vote-title);
}
.fill-dt-theme-text-text-100 {
    fill: var(--dt-theme-text-text-100);
}
.fill-dt-theme-tv-icon-avatar-icon {
    fill: var(--dt-theme-tv-icon-avatar-icon);
}
.fill-dt-theme-tv-icon-button-icon {
    fill: var(--dt-theme-tv-icon-button-icon);
}
.fill-dt-theme-tv-icon-header-header-icon {
    fill: var(--dt-theme-tv-icon-header-header-icon);
}
.fill-dt-theme-tv-icon-list-icon {
    fill: var(--dt-theme-tv-icon-list-icon);
}
.fill-dt-theme-tv-icon-sticker-icon-accent {
    fill: var(--dt-theme-tv-icon-sticker-icon-accent);
}
.fill-dt-theme-tv-icon-sticker-icon-emphasis {
    fill: var(--dt-theme-tv-icon-sticker-icon-emphasis);
}
.fill-dt-theme-tv-icon-sticker-icon-primary {
    fill: var(--dt-theme-tv-icon-sticker-icon-primary);
}
.fill-dt-theme-tv-icon-sticker-icon-secondary {
    fill: var(--dt-theme-tv-icon-sticker-icon-secondary);
}
.stroke-dt-theme-background-progressbar-progressbar {
    stroke: var(--dt-theme-background-progressbar-progressbar);
}
.stroke-dt-theme-background-progressbar-progressbar-forced-dark {
    stroke: var(--dt-theme-background-progressbar-progressbar-forced-dark);
}
.stroke-dt-theme-surface-progressbar-progressbar-circle {
    stroke: var(--dt-theme-surface-progressbar-progressbar-circle);
}
.object-contain {
    -o-object-fit: contain;
       object-fit: contain;
}
.object-cover {
    -o-object-fit: cover;
       object-fit: cover;
}
.p-0 {
    padding: 0;
}
.p-12 {
    padding: 0.75rem;
}
.p-16 {
    padding: 1rem;
}
.p-40 {
    padding: 2.5rem;
}
.p-6 {
    padding: 0.375rem;
}
.p-8 {
    padding: 0.5rem;
}
.p-dt-spacing-100 {
    padding: 0.5rem;
}
.p-dt-spacing-150 {
    padding: 0.75rem;
}
.p-dt-spacing-200 {
    padding: 1rem;
}
.p-dt-spacing-400 {
    padding: 2rem;
}
.p-dt-spacing-50 {
    padding: 0.25rem;
}
.p-dt-spacing-75 {
    padding: 0.375rem;
}
.p-dt-spacing-none {
    padding: 0;
}
.px-20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.px-50 {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
}
.px-dt-spacing-100 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.px-dt-spacing-1400 {
    padding-left: 5.625rem;
    padding-right: 5.625rem;
}
.px-dt-spacing-200 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-dt-spacing-250 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.px-dt-spacing-300 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.px-dt-spacing-400 {
    padding-left: 2rem;
    padding-right: 2rem;
}
.px-dt-spacing-450 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
}
.px-dt-spacing-50 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.px-dt-spacing-500 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
.px-dt-spacing-75 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
}
.px-dt-spacing-none {
    padding-left: 0;
    padding-right: 0;
}
.py-15 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
}
.py-dt-spacing-100 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.py-dt-spacing-150 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.py-dt-spacing-175 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
}
.py-dt-spacing-200 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-dt-spacing-25 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}
.py-dt-spacing-250 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}
.py-dt-spacing-350 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
}
.py-dt-spacing-400 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.py-dt-spacing-50 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.py-dt-spacing-600 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.py-dt-spacing-none {
    padding-top: 0;
    padding-bottom: 0;
}
.pb-dt-spacing-100 {
    padding-bottom: 0.5rem;
}
.pb-dt-spacing-200 {
    padding-bottom: 1rem;
}
.pb-dt-spacing-225 {
    padding-bottom: 1.125rem;
}
.pb-dt-spacing-250 {
    padding-bottom: 1.25rem;
}
.pb-dt-spacing-400 {
    padding-bottom: 2rem;
}
.pb-dt-spacing-50 {
    padding-bottom: 0.25rem;
}
.pb-dt-spacing-600 {
    padding-bottom: 3rem;
}
.pl-dt-spacing-100 {
    padding-left: 0.5rem;
}
.pl-dt-spacing-150 {
    padding-left: 0.75rem;
}
.pl-dt-spacing-200 {
    padding-left: 1rem;
}
.pl-dt-spacing-250 {
    padding-left: 1.25rem;
}
.pl-dt-spacing-350 {
    padding-left: 1.75rem;
}
.pl-dt-spacing-50 {
    padding-left: 0.25rem;
}
.pr-dt-spacing-200 {
    padding-right: 1rem;
}
.pr-dt-spacing-300 {
    padding-right: 1.5rem;
}
.pr-dt-spacing-425 {
    padding-right: 2.125rem;
}
.pr-dt-spacing-50 {
    padding-right: 0.25rem;
}
.pr-dt-spacing-600 {
    padding-right: 3rem;
}
.ps-dt-spacing-none {
    padding-inline-start: 0;
}
.pt-2 {
    padding-top: 0.125rem;
}
.pt-dt-spacing-100 {
    padding-top: 0.5rem;
}
.pt-dt-spacing-1000 {
    padding-top: 5rem;
}
.pt-dt-spacing-200 {
    padding-top: 1rem;
}
.pt-dt-spacing-25 {
    padding-top: 0.125rem;
}
.pt-dt-spacing-250 {
    padding-top: 1.25rem;
}
.pt-dt-spacing-300 {
    padding-top: 1.5rem;
}
.pt-dt-spacing-350 {
    padding-top: 1.75rem;
}
.pt-dt-spacing-50 {
    padding-top: 0.25rem;
}
.pt-dt-spacing-750 {
    padding-top: 3.75rem;
}
.pt-dt-spacing-none {
    padding-top: 0;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-start {
    text-align: start;
}
.align-top {
    vertical-align: top;
}
.align-middle {
    vertical-align: middle;
}
.font-dt-font-family-brand {
    font-family: Canal, "Arial Black", Arial, sans-serif;
}
.font-dt-font-family-system {
    font-family: Hind, Arial, sans-serif;
}
.text-dt-font-size-12 {
    font-size: 0.75rem;
}
.text-dt-font-size-14 {
    font-size: 0.875rem;
}
.text-dt-font-size-16 {
    font-size: 1rem;
}
.text-dt-font-size-18 {
    font-size: 1.125rem;
}
.text-dt-font-size-20 {
    font-size: 1.25rem;
}
.text-dt-font-size-22 {
    font-size: 1.375rem;
}
.text-dt-font-size-24 {
    font-size: 1.5rem;
}
.text-dt-font-size-28 {
    font-size: 1.75rem;
}
.text-dt-font-size-30 {
    font-size: 1.875rem;
}
.text-dt-font-size-32 {
    font-size: 2rem;
}
.text-dt-font-size-42 {
    font-size: 2.625rem;
}
.font-dt-font-weight-bold-italic {
    font-weight: 700;
}
.font-dt-font-weight-regular {
    font-weight: 400;
}
.font-dt-font-weight-semibold {
    font-weight: 600;
}
.uppercase {
    text-transform: uppercase;
}
.leading-dt-font-line-height-12 {
    line-height: 0.75rem;
}
.leading-dt-font-line-height-14 {
    line-height: 0.875rem;
}
.leading-dt-font-line-height-16 {
    line-height: 1rem;
}
.leading-dt-font-line-height-18 {
    line-height: 1.125rem;
}
.leading-dt-font-line-height-20 {
    line-height: 1.25rem;
}
.leading-dt-font-line-height-22 {
    line-height: 1.375rem;
}
.leading-dt-font-line-height-24 {
    line-height: 1.5rem;
}
.leading-dt-font-line-height-26 {
    line-height: 1.625rem;
}
.leading-dt-font-line-height-28 {
    line-height: 1.75rem;
}
.leading-dt-font-line-height-32 {
    line-height: 2rem;
}
.leading-dt-font-line-height-36 {
    line-height: 2.25rem;
}
.leading-dt-font-line-height-52 {
    line-height: 3.25rem;
}
.leading-none {
    line-height: 1;
}
.leading-normal {
    line-height: 1.5;
}
.text-\[--dt-global-palette-white-100\] {
    color: var(--dt-global-palette-white-100);
}
.text-dt-theme-icon-password-input-field-icon {
    color: var(--dt-theme-icon-password-input-field-icon);
}
.text-dt-theme-icon-text-input-field-icon {
    color: var(--dt-theme-icon-text-input-field-icon);
}
.text-dt-theme-link-link-accent {
    color: var(--dt-theme-link-link-accent);
}
.text-dt-theme-link-link-default {
    color: var(--dt-theme-link-link-default);
}
.text-dt-theme-text-accordion-accordion-body {
    color: var(--dt-theme-text-accordion-accordion-body);
}
.text-dt-theme-text-accordion-accordion-subtitle {
    color: var(--dt-theme-text-accordion-accordion-subtitle);
}
.text-dt-theme-text-accordion-accordion-title {
    color: var(--dt-theme-text-accordion-accordion-title);
}
.text-dt-theme-text-alert-box-alert-box {
    color: var(--dt-theme-text-alert-box-alert-box);
}
.text-dt-theme-text-avatar-avatar {
    color: var(--dt-theme-text-avatar-avatar);
}
.text-dt-theme-text-button-icon-button-icon {
    color: var(--dt-theme-text-button-icon-button-icon);
}
.text-dt-theme-text-button-primary-primary {
    color: var(--dt-theme-text-button-primary-primary);
}
.text-dt-theme-text-button-secondary-secondary {
    color: var(--dt-theme-text-button-secondary-secondary);
}
.text-dt-theme-text-button-tertiary-tertiary {
    color: var(--dt-theme-text-button-tertiary-tertiary);
}
.text-dt-theme-text-button-text-accent-default {
    color: var(--dt-theme-text-button-text-accent-default);
}
.text-dt-theme-text-card-timeline-facts-body {
    color: var(--dt-theme-text-card-timeline-facts-body);
}
.text-dt-theme-text-checkbox-checkbox-label {
    color: var(--dt-theme-text-checkbox-checkbox-label);
}
.text-dt-theme-text-checkbox-checkbox-sublabel {
    color: var(--dt-theme-text-checkbox-checkbox-sublabel);
}
.text-dt-theme-text-episode-list-episode-list-description {
    color: var(--dt-theme-text-episode-list-episode-list-description);
}
.text-dt-theme-text-header-header-active {
    color: var(--dt-theme-text-header-header-active);
}
.text-dt-theme-text-header-header-default {
    color: var(--dt-theme-text-header-header-default);
}
.text-dt-theme-text-header-header-disabled {
    color: var(--dt-theme-text-header-header-disabled);
}
.text-dt-theme-text-input-field-alert-input-field-alert {
    color: var(--dt-theme-text-input-field-alert-input-field-alert);
}
.text-dt-theme-text-list-ranking-list-ranking-number {
    color: var(--dt-theme-text-list-ranking-list-ranking-number);
}
.text-dt-theme-text-list-ranking-list-ranking-point {
    color: var(--dt-theme-text-list-ranking-list-ranking-point);
}
.text-dt-theme-text-list-ranking-list-ranking-title {
    color: var(--dt-theme-text-list-ranking-list-ranking-title);
}
.text-dt-theme-text-list-row-list-description {
    color: var(--dt-theme-text-list-row-list-description);
}
.text-dt-theme-text-list-row-list-row-label {
    color: var(--dt-theme-text-list-row-list-row-label);
}
.text-dt-theme-text-list-row-list-row-label-disabled {
    color: var(--dt-theme-text-list-row-list-row-label-disabled);
}
.text-dt-theme-text-list-row-list-row-price {
    color: var(--dt-theme-text-list-row-list-row-price);
}
.text-dt-theme-text-list-row-list-row-price-disabled {
    color: var(--dt-theme-text-list-row-list-row-price-disabled);
}
.text-dt-theme-text-list-row-list-row-promotion {
    color: var(--dt-theme-text-list-row-list-row-promotion);
}
.text-dt-theme-text-list-row-list-row-promotion-disabled {
    color: var(--dt-theme-text-list-row-list-row-promotion-disabled);
}
.text-dt-theme-text-list-row-list-row-subtitle {
    color: var(--dt-theme-text-list-row-list-row-subtitle);
}
.text-dt-theme-text-list-row-list-row-subtitle-disabled {
    color: var(--dt-theme-text-list-row-list-row-subtitle-disabled);
}
.text-dt-theme-text-list-row-list-row-title-disabled {
    color: var(--dt-theme-text-list-row-list-row-title-disabled);
}
.text-dt-theme-text-list-row-list-title {
    color: var(--dt-theme-text-list-row-list-title);
}
.text-dt-theme-text-list-statistics-list-statistics-primary-data {
    color: var(--dt-theme-text-list-statistics-list-statistics-primary-data);
}
.text-dt-theme-text-list-statistics-list-statistics-secondary-data {
    color: var(--dt-theme-text-list-statistics-list-statistics-secondary-data);
}
.text-dt-theme-text-list-statistics-list-statistics-subtitle {
    color: var(--dt-theme-text-list-statistics-list-statistics-subtitle);
}
.text-dt-theme-text-list-statistics-list-statistics-title {
    color: var(--dt-theme-text-list-statistics-list-statistics-title);
}
.text-dt-theme-text-list-timeline-vote-list-timeline-vote-label {
    color: var(--dt-theme-text-list-timeline-vote-list-timeline-vote-label);
}
.text-dt-theme-text-list-timeline-vote-list-timeline-vote-title {
    color: var(--dt-theme-text-list-timeline-vote-list-timeline-vote-title);
}
.text-dt-theme-text-media-block-media-block-description {
    color: var(--dt-theme-text-media-block-media-block-description);
}
.text-dt-theme-text-media-block-media-block-inside-description {
    color: var(--dt-theme-text-media-block-media-block-inside-description);
}
.text-dt-theme-text-media-block-media-block-inside-title {
    color: var(--dt-theme-text-media-block-media-block-inside-title);
}
.text-dt-theme-text-media-block-media-block-subtitle {
    color: var(--dt-theme-text-media-block-media-block-subtitle);
}
.text-dt-theme-text-media-block-media-block-title {
    color: var(--dt-theme-text-media-block-media-block-title);
}
.text-dt-theme-text-number-access-input-field-number-access-input-field {
    color: var(--dt-theme-text-number-access-input-field-number-access-input-field);
}
.text-dt-theme-text-offer-tag-badge {
    color: var(--dt-theme-text-offer-tag-badge);
}
.text-dt-theme-text-password-input-field-password-input-field {
    color: var(--dt-theme-text-password-input-field-password-input-field);
}
.text-dt-theme-text-password-input-field-password-input-field-filled {
    color: var(--dt-theme-text-password-input-field-password-input-field-filled);
}
.text-dt-theme-text-password-input-field-password-input-field-hint {
    color: var(--dt-theme-text-password-input-field-password-input-field-hint);
}
.text-dt-theme-text-primary-accent {
    color: var(--dt-theme-text-primary-accent);
}
.text-dt-theme-text-radio-button-radio-label {
    color: var(--dt-theme-text-radio-button-radio-label);
}
.text-dt-theme-text-radio-button-radio-sublabel {
    color: var(--dt-theme-text-radio-button-radio-sublabel);
}
.text-dt-theme-text-season-selector-season-selector {
    color: var(--dt-theme-text-season-selector-season-selector);
}
.text-dt-theme-text-season-selector-season-selector-selected {
    color: var(--dt-theme-text-season-selector-season-selector-selected);
}
.text-dt-theme-text-sticker-sticker-accent {
    color: var(--dt-theme-text-sticker-sticker-accent);
}
.text-dt-theme-text-sticker-sticker-emphasis {
    color: var(--dt-theme-text-sticker-sticker-emphasis);
}
.text-dt-theme-text-sticker-sticker-primary {
    color: var(--dt-theme-text-sticker-sticker-primary);
}
.text-dt-theme-text-sticker-sticker-secondary {
    color: var(--dt-theme-text-sticker-sticker-secondary);
}
.text-dt-theme-text-text-100 {
    color: var(--dt-theme-text-text-100);
}
.text-dt-theme-text-text-50 {
    color: var(--dt-theme-text-text-50);
}
.text-dt-theme-text-text-70 {
    color: var(--dt-theme-text-text-70);
}
.text-dt-theme-text-text-input-field-text-input-field {
    color: var(--dt-theme-text-text-input-field-text-input-field);
}
.text-dt-theme-text-text-input-field-text-input-field-active {
    color: var(--dt-theme-text-text-input-field-text-input-field-active);
}
.text-dt-theme-text-toggle-switch-toggle-switch-label {
    color: var(--dt-theme-text-toggle-switch-toggle-switch-label);
}
.text-dt-theme-text-toggle-switch-toggle-switch-label-disabled {
    color: var(--dt-theme-text-toggle-switch-toggle-switch-label-disabled);
}
.text-dt-theme-text-toggle-switch-toggle-switch-sublabel {
    color: var(--dt-theme-text-toggle-switch-toggle-switch-sublabel);
}
.text-dt-theme-text-toggle-switch-toggle-switch-sublabel-disabled {
    color: var(--dt-theme-text-toggle-switch-toggle-switch-sublabel-disabled);
}
.text-dt-theme-text-tooltip-tooltip {
    color: var(--dt-theme-text-tooltip-tooltip);
}
.text-dt-theme-text-tooltip-tooltip-link-default {
    color: var(--dt-theme-text-tooltip-tooltip-link-default);
}
.text-dt-theme-tv-text-alert-banner-alert-banner {
    color: var(--dt-theme-tv-text-alert-banner-alert-banner);
}
.text-dt-theme-tv-text-alert-box-alert-box {
    color: var(--dt-theme-tv-text-alert-box-alert-box);
}
.text-dt-theme-tv-text-avatar-avatar {
    color: var(--dt-theme-tv-text-avatar-avatar);
}
.text-dt-theme-tv-text-button-button {
    color: var(--dt-theme-tv-text-button-button);
}
.text-dt-theme-tv-text-card-timeline-facts-body-default {
    color: var(--dt-theme-tv-text-card-timeline-facts-body-default);
}
.text-dt-theme-tv-text-header-header-security {
    color: var(--dt-theme-tv-text-header-header-security);
}
.text-dt-theme-tv-text-input-field-alert-input-field-alert {
    color: var(--dt-theme-tv-text-input-field-alert-input-field-alert);
}
.text-dt-theme-tv-text-list-list-group-subtitle {
    color: var(--dt-theme-tv-text-list-list-group-subtitle);
}
.text-dt-theme-tv-text-list-list-group-title {
    color: var(--dt-theme-tv-text-list-list-group-title);
}
.text-dt-theme-tv-text-list-list-row-label-default {
    color: var(--dt-theme-tv-text-list-list-row-label-default);
}
.text-dt-theme-tv-text-list-list-row-promotion-default {
    color: var(--dt-theme-tv-text-list-list-row-promotion-default);
}
.text-dt-theme-tv-text-list-list-row-sublabel-default {
    color: var(--dt-theme-tv-text-list-list-row-sublabel-default);
}
.text-dt-theme-tv-text-list-list-row-subtitle-default {
    color: var(--dt-theme-tv-text-list-list-row-subtitle-default);
}
.text-dt-theme-tv-text-list-list-row-title-default {
    color: var(--dt-theme-tv-text-list-list-row-title-default);
}
.text-dt-theme-tv-text-list-ranking-list-ranking-number {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-number);
}
.text-dt-theme-tv-text-list-ranking-list-ranking-point {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-point);
}
.text-dt-theme-tv-text-list-ranking-list-ranking-title {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-title);
}
.text-dt-theme-tv-text-list-statistics-list-statistics-primary-data {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-primary-data);
}
.text-dt-theme-tv-text-list-statistics-list-statistics-secondary-data {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-secondary-data);
}
.text-dt-theme-tv-text-list-statistics-list-statistics-subtitle {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-subtitle);
}
.text-dt-theme-tv-text-list-statistics-list-statistics-title {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-title);
}
.text-dt-theme-tv-text-list-timeline-vote-list-timeline-vote-label {
    color: var(--dt-theme-tv-text-list-timeline-vote-list-timeline-vote-label);
}
.text-dt-theme-tv-text-list-timeline-vote-list-timeline-vote-title {
    color: var(--dt-theme-tv-text-list-timeline-vote-list-timeline-vote-title);
}
.text-dt-theme-tv-text-media-block-media-block-description {
    color: var(--dt-theme-tv-text-media-block-media-block-description);
}
.text-dt-theme-tv-text-media-block-media-block-subtitle {
    color: var(--dt-theme-tv-text-media-block-media-block-subtitle);
}
.text-dt-theme-tv-text-media-block-media-block-title {
    color: var(--dt-theme-tv-text-media-block-media-block-title);
}
.text-dt-theme-tv-text-offer-tag-offer-tag {
    color: var(--dt-theme-tv-text-offer-tag-offer-tag);
}
.text-dt-theme-tv-text-password-input-password-input-hint {
    color: var(--dt-theme-tv-text-password-input-password-input-hint);
}
.text-dt-theme-tv-text-season-selector-season-selector-default {
    color: var(--dt-theme-tv-text-season-selector-season-selector-default);
}
.text-dt-theme-tv-text-season-selector-season-selector-description {
    color: var(--dt-theme-tv-text-season-selector-season-selector-description);
}
.text-dt-theme-tv-text-season-selector-season-selector-select {
    color: var(--dt-theme-tv-text-season-selector-season-selector-select);
}
.text-dt-theme-tv-text-sticker-sticker-accent {
    color: var(--dt-theme-tv-text-sticker-sticker-accent);
}
.text-dt-theme-tv-text-sticker-sticker-emphasis {
    color: var(--dt-theme-tv-text-sticker-sticker-emphasis);
}
.text-dt-theme-tv-text-sticker-sticker-primary {
    color: var(--dt-theme-tv-text-sticker-sticker-primary);
}
.text-dt-theme-tv-text-sticker-sticker-secondary {
    color: var(--dt-theme-tv-text-sticker-sticker-secondary);
}
.underline {
    text-decoration-line: underline;
}
.no-underline {
    text-decoration-line: none;
}
.placeholder-transparent::-moz-placeholder {
    color: rgba(0, 0, 0, 0);
}
.placeholder-transparent::placeholder {
    color: rgba(0, 0, 0, 0);
}
.caret-dt-theme-border-password-input-field-password-input-field-cursor {
    caret-color: var(--dt-theme-border-password-input-field-password-input-field-cursor);
}
.caret-dt-theme-border-text-input-field-text-input-field-cursor {
    caret-color: var(--dt-theme-border-text-input-field-text-input-field-cursor);
}
.opacity-0 {
    opacity: 0;
}
.opacity-50 {
    opacity: 0.5;
}
.opacity-80 {
    opacity: 0.8;
}
.opacity-dt-opacity-100 {
    opacity: 100%;
}
.opacity-dt-opacity-30 {
    opacity: 30%;
}
.opacity-dt-opacity-40 {
    opacity: 40%;
}
.opacity-dt-opacity-50 {
    opacity: 50%;
}
.opacity-dt-opacity-80 {
    opacity: 80%;
}
.opacity-dt-opacity-none {
    opacity: 0%;
}
.shadow-card {
    --tw-shadow: 0 0.125rem 1.25rem 0 var(--dt-global-palette-black-opacity-30);
    --tw-shadow-colored: 0 0.125rem 1.25rem 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.outline {
    outline-style: solid;
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-none {
    transition-property: none;
}
.transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.duration-0 {
    transition-duration: 0s;
}
.duration-1000 {
    transition-duration: 1000ms;
}
.duration-150 {
    transition-duration: 150ms;
}
.duration-200 {
    transition-duration: 200ms;
}
.duration-300 {
    transition-duration: 300ms;
}
.ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.font-dt-system-hind-body-body-1-regular-tv {
    font: var(--dt-system-hind-body-body-1-regular-tv);
}
.font-dt-system-hind-body-body-1-regular {
    font: var(--dt-system-hind-body-body-1-regular);
}
.font-dt-system-hind-body-body-2-regular-tv {
    font: var(--dt-system-hind-body-body-2-regular-tv);
}
.font-dt-system-hind-body-body-2-semi-bold {
    font: var(--dt-system-hind-body-body-2-semi-bold);
}
.font-dt-system-hind-body-body-3-regular-tv {
    font: var(--dt-system-hind-body-body-3-regular-tv);
}
.font-dt-system-hind-body-body-3-regular {
    font: var(--dt-system-hind-body-body-3-regular);
}
.font-dt-system-hind-label-label-3-regular-tv {
    font: var(--dt-system-hind-label-label-3-regular-tv);
}
.font-dt-system-hind-label-label-3-regular {
    font: var(--dt-system-hind-label-label-3-regular);
}
.font-dt-system-hind-label-label-3-semi-bold-tv {
    font: var(--dt-system-hind-label-label-3-semi-bold-tv);
}
.font-dt-system-hind-label-label-4-semi-bold {
    font: var(--dt-system-hind-label-label-4-semi-bold);
}
.font-dt-system-hind-label-label-5-regular-tv {
    font: var(--dt-system-hind-label-label-5-regular-tv);
}
.font-dt-system-hind-label-label-5-regular {
    font: var(--dt-system-hind-label-label-5-regular);
}
.font-dt-system-hind-label-label-5-semi-bold-tv {
    font: var(--dt-system-hind-label-label-5-semi-bold-tv);
}
.font-dt-system-hind-label-label-5-semi-bold {
    font: var(--dt-system-hind-label-label-5-semi-bold);
}
.font-dt-system-hind-title-title-4-regular-tv {
    font: var(--dt-system-hind-title-title-4-regular-tv);
}
.font-dt-system-hind-title-title-5-regular-tv {
    font: var(--dt-system-hind-title-title-5-regular-tv);
}
.font-dt-system-hind-title-title-5-regular {
    font: var(--dt-system-hind-title-title-5-regular);
}
.font-dt-system-hind-title-title-5-semi-bold-tv {
    font: var(--dt-system-hind-title-title-5-semi-bold-tv);
}
.font-dt-system-hind-title-title-7-regular {
    font: var(--dt-system-hind-title-title-7-regular);
}
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
/* Safari, Chrome and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
  }
/*
    Remove default mobile active/touch background
    ! TODO: whenever `@tailwind base;` is reinstated, remove this, since already defined for HTML and :root
  */
.no-tap-highlight-color {
    -webkit-tap-highlight-color: transparent;
  }
/*

  Default `@tailwind base;` imported at the top of the file causes conflict with the
  legacy myCANAL Button components styles,
  hence the custom fallback classes and internal --tw variables declarations below.

  ! TODO: whenever `@tailwind base;` is reinstated,
  the fallback classes below should be removed and replaced by the native ones: 'tv-focus-self:ring-4 ring-primary'

  Tailwind's ring utility renders a pseudo-outline based on CSS property box-shadow.
  This addresses an issue specific to Safari<16.4 rendering `outline` with squared edges
  https://webkit.org/blog/13966/webkit-features-in-safari-16-4/#:~:text=Outline%20+%20Border%20Radius

  A `@tailwind base;` import at the top of the current file is necessary
  for the ring utility to work.
  This import is currently blocked as it causes conflict with the
  legacy myCANAL Button components styles, hence the fallback classes below.

  ! TODO: whenever `@tailwind base;` is reinstated, the fallback classes below should be removed and replaced by the native ones: 'tv-focus-self:ring-4 ring-primary'
    */
.ring-op50-4 {
    box-shadow: var(--dt-theme-text-text-50) 0 0 0 4px;
  }
.clip-left-1\/2 {
    clip-path: inset(0 50% 0 0);
  }
*,
  ::before,
  ::after {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
  }
/* Default values for the list-statistics component */
.list-statistics-base {
    --percentage: 50%;
    --gap-width: 0.1875rem;
    --gap-percentage-start: calc(var(--percentage) - var(--gap-width) / 2);
    --gap-percentage-end: calc(var(--percentage) + var(--gap-width) / 2);
    --left-color: var(
      --dt-theme-surface-progressbar-statistics-progressbar-statistics-active
    );
    --right-color: var(
      --dt-theme-surface-progressbar-statistics-progressbar-statistics-default
    );
  }
/* dt-breakpoint-sm-start */
@media screen and (min-width: 700px) {
  .list-statistics-base {
      --gap-width: 0.3125rem
  }
    }
.list-statistics-base-tv {
    --gap-width: 0.5rem;
    --left-color: var(
      --dt-theme-tv-surface-progressbar-statistics-progressbar-statistics-active
    );
    --right-color: var(
      --dt-theme-tv-surface-progressbar-statistics-progressbar-statistics-default
    );
  }
.\[grid-template-columns\:repeat\(auto-fit\2c minmax\(220px\2c 1fr\)\)\] {
    grid-template-columns: repeat(auto-fit,minmax(220px,1fr));
}
.\[\&\>div\]\:aspect-h-1>div {
    --tw-aspect-h: 1;
}
.\[\&\>div\]\:aspect-w-1>div {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 1;
}
.\[\&\>div\]\:aspect-w-1>div > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.placeholder\:text-dt-theme-text-password-input-field-password-input-field::-moz-placeholder {
    color: var(--dt-theme-text-password-input-field-password-input-field);
}
.placeholder\:text-dt-theme-text-password-input-field-password-input-field::placeholder {
    color: var(--dt-theme-text-password-input-field-password-input-field);
}
.placeholder\:text-dt-theme-text-text-input-field-text-input-field::-moz-placeholder {
    color: var(--dt-theme-text-text-input-field-text-input-field);
}
.placeholder\:text-dt-theme-text-text-input-field-text-input-field::placeholder {
    color: var(--dt-theme-text-text-input-field-text-input-field);
}
.placeholder\:opacity-0::-moz-placeholder {
    opacity: 0;
}
.placeholder\:opacity-0::placeholder {
    opacity: 0;
}
.placeholder\:transition-opacity::-moz-placeholder {
    -moz-transition-property: opacity;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.placeholder\:transition-opacity::placeholder {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.before\:pointer-events-none::before {
    content: var(--tw-content);
    pointer-events: none;
}
.before\:absolute::before {
    content: var(--tw-content);
    position: absolute;
}
.before\:inset-y-0::before {
    content: var(--tw-content);
    top: 0;
    bottom: 0;
}
.before\:bottom-0::before {
    content: var(--tw-content);
    bottom: 0;
}
.before\:left-0::before {
    content: var(--tw-content);
    left: 0;
}
.before\:left-12::before {
    content: var(--tw-content);
    left: 0.75rem;
}
.before\:left-8::before {
    content: var(--tw-content);
    left: 0.5rem;
}
.before\:top-0::before {
    content: var(--tw-content);
    top: 0;
}
.before\:-z-1::before {
    content: var(--tw-content);
    z-index: -1;
}
.before\:z-0::before {
    content: var(--tw-content);
    z-index: 0;
}
.before\:z-10::before {
    content: var(--tw-content);
    z-index: 10;
}
.before\:block::before {
    content: var(--tw-content);
    display: block;
}
.before\:size-10::before {
    content: var(--tw-content);
    width: 0.625rem;
    height: 0.625rem;
}
.before\:size-8::before {
    content: var(--tw-content);
    width: 0.5rem;
    height: 0.5rem;
}
.before\:size-full::before {
    content: var(--tw-content);
    width: 100%;
    height: 100%;
}
.before\:h-\[20rem\]::before {
    content: var(--tw-content);
    height: 20rem;
}
.before\:h-\[8\.75rem\]::before {
    content: var(--tw-content);
    height: 8.75rem;
}
.before\:h-full::before {
    content: var(--tw-content);
    height: 100%;
}
.before\:w-16::before {
    content: var(--tw-content);
    width: 1rem;
}
.before\:w-full::before {
    content: var(--tw-content);
    width: 100%;
}
@keyframes pulse {
    50% {
        content: var(--tw-content);
        opacity: 0.2;
    }
    0% {
        content: var(--tw-content);
        opacity: 1;
    }
    100% {
        content: var(--tw-content);
        opacity: 1;
    }
}
.before\:animate-pulse-6s::before {
    content: var(--tw-content);
    animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) 4 none;
}
.before\:rounded-full::before {
    content: var(--tw-content);
    border-radius: 9999px;
}
.before\:bg-dt-theme-background-load-media-placeholder-load-media-placeholder::before {
    content: var(--tw-content);
    background-color: var(--dt-theme-background-load-media-placeholder-load-media-placeholder);
}
.before\:bg-dt-theme-surface-number-access-input-field-number-access-input-field-active::before {
    content: var(--tw-content);
    background-color: var(--dt-theme-surface-number-access-input-field-number-access-input-field-active);
}
.before\:bg-dt-theme-surface-progressbar-progressbar-live::before {
    content: var(--tw-content);
    background-color: var(--dt-theme-surface-progressbar-progressbar-live);
}
.before\:bg-dt-theme-tv-background-load-media-placeholder-load-media-placeholder::before {
    content: var(--tw-content);
    background-color: var(--dt-theme-tv-background-load-media-placeholder-load-media-placeholder);
}
.before\:bg-dt-theme-tv-surface-progressbar-progressbar-live::before {
    content: var(--tw-content);
    background-color: var(--dt-theme-tv-surface-progressbar-progressbar-live);
}
.before\:bg-gradient-to-b::before {
    content: var(--tw-content);
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}
.before\:bg-gradient-to-r::before {
    content: var(--tw-content);
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.before\:bg-media-overlay-gradient-logo::before {
    content: var(--tw-content);
    background-image: radial-gradient(
          60% 90% at 0% 100%,
          var(--dt-theme-gradient-media-logo-gradient-gradient-start) 0%,
          var(--dt-theme-gradient-media-logo-gradient-gradient-end) 100%);
}
.before\:from-dt-theme-gradient-header-gradient-gradient-start::before {
    content: var(--tw-content);
    --tw-gradient-from: var(--dt-theme-gradient-header-gradient-gradient-start) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.before\:from-dt-theme-gradient-header-nav-left-gradient-gradient-start::before {
    content: var(--tw-content);
    --tw-gradient-from: var(--dt-theme-gradient-header-nav-left-gradient-gradient-start) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.before\:to-dt-theme-gradient-header-gradient-gradient-end::before {
    content: var(--tw-content);
    --tw-gradient-to: var(--dt-theme-gradient-header-gradient-gradient-end) var(--tw-gradient-to-position);
}
.before\:to-dt-theme-gradient-header-nav-left-gradient-gradient-end::before {
    content: var(--tw-content);
    --tw-gradient-to: var(--dt-theme-gradient-header-nav-left-gradient-gradient-end) var(--tw-gradient-to-position);
}
.before\:content-\[\"\"\]::before {
    --tw-content: "";
    content: var(--tw-content);
}
.before\:content-\[\'\'\]::before {
    --tw-content: '';
    content: var(--tw-content);
}
.before\:absolute-y-center::before {
    content: var(--tw-content);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
.after\:pointer-events-none::after {
    content: var(--tw-content);
    pointer-events: none;
}
.after\:absolute::after {
    content: var(--tw-content);
    position: absolute;
}
.after\:inset-y-0::after {
    content: var(--tw-content);
    top: 0;
    bottom: 0;
}
.after\:-bottom-1::after {
    content: var(--tw-content);
    bottom: -0.0625rem;
}
.after\:bottom-0::after {
    content: var(--tw-content);
    bottom: 0;
}
.after\:left-0::after {
    content: var(--tw-content);
    left: 0;
}
.after\:left-16::after {
    content: var(--tw-content);
    left: 1rem;
}
.after\:left-\[50\%\]::after {
    content: var(--tw-content);
    left: 50%;
}
.after\:right-0::after {
    content: var(--tw-content);
    right: 0;
}
.after\:right-16::after {
    content: var(--tw-content);
    right: 1rem;
}
.after\:z-10::after {
    content: var(--tw-content);
    z-index: 10;
}
.after\:block::after {
    content: var(--tw-content);
    display: block;
}
.after\:size-12::after {
    content: var(--tw-content);
    width: 0.75rem;
    height: 0.75rem;
}
.after\:size-8::after {
    content: var(--tw-content);
    width: 0.5rem;
    height: 0.5rem;
}
.after\:h-1::after {
    content: var(--tw-content);
    height: 0.0625rem;
}
.after\:w-16::after {
    content: var(--tw-content);
    width: 1rem;
}
.after\:translate-x-\[-50\%\]::after {
    content: var(--tw-content);
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.after\:rounded-dt-radius-circle::after {
    content: var(--tw-content);
    border-radius: 6.25rem;
}
.after\:rounded-full::after {
    content: var(--tw-content);
    border-radius: 9999px;
}
.after\:bg-dt-theme-background-number-access-input-field-bullet::after {
    content: var(--tw-content);
    background-color: var(--dt-theme-background-number-access-input-field-bullet);
}
.after\:bg-dt-theme-border-list-row-divider::after {
    content: var(--tw-content);
    background-color: var(--dt-theme-border-list-row-divider);
}
.after\:bg-dt-theme-tv-icon-button-icon-icon-alert-default::after {
    content: var(--tw-content);
    background-color: var(--dt-theme-tv-icon-button-icon-icon-alert-default);
}
.after\:bg-gradient-to-l::after {
    content: var(--tw-content);
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
}
.after\:from-dt-theme-gradient-header-nav-right-gradient-gradient-start::after {
    content: var(--tw-content);
    --tw-gradient-from: var(--dt-theme-gradient-header-nav-right-gradient-gradient-start) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.after\:to-dt-theme-gradient-header-nav-right-gradient-gradient-end::after {
    content: var(--tw-content);
    --tw-gradient-to: var(--dt-theme-gradient-header-nav-right-gradient-gradient-end) var(--tw-gradient-to-position);
}
.after\:content-\[\"\"\]::after {
    --tw-content: "";
    content: var(--tw-content);
}
.after\:content-\[\'\'\]::after {
    --tw-content: '';
    content: var(--tw-content);
}
.first\:rounded-tl-dt-radius-small:first-child {
    border-top-left-radius: 0.125rem;
}
.first\:rounded-tr-dt-radius-small:first-child {
    border-top-right-radius: 0.125rem;
}
.last\:rounded-bl-dt-radius-small:last-child {
    border-bottom-left-radius: 0.125rem;
}
.last\:rounded-br-dt-radius-small:last-child {
    border-bottom-right-radius: 0.125rem;
}
.focus-within\:scale-tv-focus:focus-within {
    --tw-scale-x: 1.04;
    --tw-scale-y: 1.04;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.focus-within\:border-none:focus-within {
    border-style: none;
}
.focus-within\:border-dt-theme-border-number-access-input-field-number-access-input-field-active:focus-within {
    border-color: var(--dt-theme-border-number-access-input-field-number-access-input-field-active);
}
.focus-within\:border-dt-theme-border-password-input-field-password-input-field-active:focus-within {
    border-color: var(--dt-theme-border-password-input-field-password-input-field-active);
}
.focus-within\:border-dt-theme-border-password-input-field-password-input-field-error-active:focus-within {
    border-color: var(--dt-theme-border-password-input-field-password-input-field-error-active);
}
.focus-within\:border-dt-theme-border-text-input-field-text-input-field-active:focus-within {
    border-color: var(--dt-theme-border-text-input-field-text-input-field-active);
}
.focus-within\:border-dt-theme-border-text-input-field-text-input-field-error-active:focus-within {
    border-color: var(--dt-theme-border-text-input-field-text-input-field-error-active);
}
.focus-within\:border-dt-theme-border-text-input-field-text-input-field-success-active:focus-within {
    border-color: var(--dt-theme-border-text-input-field-text-input-field-success-active);
}
.focus-within\:border-dt-theme-border-text-input-field-text-input-field-warning-active:focus-within {
    border-color: var(--dt-theme-border-text-input-field-text-input-field-warning-active);
}
.focus-within\:bg-dt-theme-tv-surface-list-list-focus:focus-within {
    background-color: var(--dt-theme-tv-surface-list-list-focus);
}
.focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.hover\:scale-tv-focus:hover {
    --tw-scale-x: 1.04;
    --tw-scale-y: 1.04;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.hover\:bg-dt-theme-background-button-primary-accent-hover:hover {
    background-color: var(--dt-theme-background-button-primary-accent-hover);
}
.hover\:bg-dt-theme-background-button-secondary-secondary-hover:hover {
    background-color: var(--dt-theme-background-button-secondary-secondary-hover);
}
.hover\:bg-dt-theme-surface-card-tab-card-tab-hover:hover {
    background-color: var(--dt-theme-surface-card-tab-card-tab-hover);
}
.hover\:bg-dt-theme-surface-list-row-list-row-hover:hover {
    background-color: var(--dt-theme-surface-list-row-list-row-hover);
}
.hover\:bg-dt-theme-surface-season-selector-season-selector-selected:hover {
    background-color: var(--dt-theme-surface-season-selector-season-selector-selected);
}
.hover\:text-dt-theme-link-link-accent-hover:hover {
    color: var(--dt-theme-link-link-accent-hover);
}
.hover\:text-dt-theme-link-link-hover:hover {
    color: var(--dt-theme-link-link-hover);
}
.hover\:text-dt-theme-text-season-selector-season-selector-selected:hover {
    color: var(--dt-theme-text-season-selector-season-selector-selected);
}
.hover\:outline-none:hover {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus\:border-dt-theme-border-border-active:focus {
    border-color: var(--dt-theme-border-border-active);
}
.focus\:bg-dt-theme-surface-card-tab-card-tab-hover:focus {
    background-color: var(--dt-theme-surface-card-tab-card-tab-hover);
}
.focus\:text-dt-theme-text-password-input-field-password-input-field-active:focus {
    color: var(--dt-theme-text-password-input-field-password-input-field-active);
}
.focus\:placeholder-dt-theme-text-number-access-input-field-number-access-input-field-active:focus::-moz-placeholder {
    color: var(--dt-theme-text-number-access-input-field-number-access-input-field-active);
}
.focus\:placeholder-dt-theme-text-number-access-input-field-number-access-input-field-active:focus::placeholder {
    color: var(--dt-theme-text-number-access-input-field-number-access-input-field-active);
}
.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus\:placeholder\:opacity-100:focus::-moz-placeholder {
    opacity: 1;
}
.focus\:placeholder\:opacity-100:focus::placeholder {
    opacity: 1;
}
.focus-visible\:outline-none:focus-visible {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus-visible\:outline:focus-visible {
    outline-style: solid;
}
.focus-visible\:outline-offset-4:focus-visible {
    outline-offset: 4px;
}
.focus-visible\:outline-dt-theme-border-radio-button-radio-button:focus-visible {
    outline-color: var(--dt-theme-border-radio-button-radio-button);
}
.active\:bg-dt-theme-background-button-primary-accent-pressed:active {
    background-color: var(--dt-theme-background-button-primary-accent-pressed);
}
.active\:bg-dt-theme-background-button-secondary-secondary-pressed:active {
    background-color: var(--dt-theme-background-button-secondary-secondary-pressed);
}
.active\:text-dt-theme-link-link-accent-pressed:active {
    color: var(--dt-theme-link-link-accent-pressed);
}
.active\:text-dt-theme-link-link-pressed:active {
    color: var(--dt-theme-link-link-pressed);
}
.active\:decoration-dt-theme-link-link-pressed:active {
    text-decoration-color: var(--dt-theme-link-link-pressed);
}
.enabled\:cursor-pointer:enabled {
    cursor: pointer;
}
.disabled\:cursor-not-allowed:disabled {
    cursor: not-allowed;
}
.disabled\:text-dt-theme-text-number-access-input-field-number-access-input-field-disabled:disabled {
    color: var(--dt-theme-text-number-access-input-field-number-access-input-field-disabled);
}
.disabled\:text-dt-theme-text-password-input-field-password-input-field-disabled:disabled {
    color: var(--dt-theme-text-password-input-field-password-input-field-disabled);
}
.disabled\:text-dt-theme-text-text-input-field-text-input-field-disabled:disabled {
    color: var(--dt-theme-text-text-input-field-text-input-field-disabled);
}
.group:hover .group-hover\:scale-button-icon {
    --tw-scale-x: 1.125;
    --tw-scale-y: 1.125;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group:hover .group-hover\:bg-dt-theme-background-button-icon-button-icon-primary-hover {
    background-color: var(--dt-theme-background-button-icon-button-icon-primary-hover);
}
.group:hover .group-hover\:fill-dt-theme-icon-button-text-icon-accent-hover {
    fill: var(--dt-theme-icon-button-text-icon-accent-hover);
}
.group:hover .group-hover\:fill-dt-theme-icon-header-icon-active {
    fill: var(--dt-theme-icon-header-icon-active);
}
.group:hover .group-hover\:fill-dt-theme-icon-link-link-icon-accent-hover {
    fill: var(--dt-theme-icon-link-link-icon-accent-hover);
}
.group:hover .group-hover\:fill-dt-theme-icon-link-link-icon-hover {
    fill: var(--dt-theme-icon-link-link-icon-hover);
}
.group:hover .group-hover\:text-dt-theme-icon-header-icon-active {
    color: var(--dt-theme-icon-header-icon-active);
}
.group:hover .group-hover\:text-dt-theme-text-button-icon-button-icon-hover {
    color: var(--dt-theme-text-button-icon-button-icon-hover);
}
.group:hover .group-hover\:text-dt-theme-text-button-text-accent-hover {
    color: var(--dt-theme-text-button-text-accent-hover);
}
.group:focus .group-focus\:bg-dt-theme-tv-background-button-icon-button-icon-focus {
    background-color: var(--dt-theme-tv-background-button-icon-button-icon-focus);
}
.group:focus .group-focus\:fill-dt-theme-tv-icon-button-icon-focus {
    fill: var(--dt-theme-tv-icon-button-icon-focus);
}
.group:active .group-active\:fill-dt-theme-icon-button-text-icon-accent-pressed {
    fill: var(--dt-theme-icon-button-text-icon-accent-pressed);
}
.group:active .group-active\:fill-dt-theme-icon-link-link-icon-accent-pressed {
    fill: var(--dt-theme-icon-link-link-icon-accent-pressed);
}
.group:active .group-active\:fill-dt-theme-icon-link-link-icon-pressed {
    fill: var(--dt-theme-icon-link-link-icon-pressed);
}
.group:active .group-active\:text-dt-theme-text-button-text-accent-pressed {
    color: var(--dt-theme-text-button-text-accent-pressed);
}
.peer:-moz-placeholder ~ .peer-placeholder-shown\:top-16 {
    top: 1rem;
}
.peer:placeholder-shown ~ .peer-placeholder-shown\:top-16 {
    top: 1rem;
}
.peer:-moz-placeholder ~ .peer-placeholder-shown\:text-dt-font-size-14 {
    font-size: 0.875rem;
}
.peer:placeholder-shown ~ .peer-placeholder-shown\:text-dt-font-size-14 {
    font-size: 0.875rem;
}
.peer:-moz-placeholder ~ .peer-placeholder-shown\:leading-dt-font-line-height-18 {
    line-height: 1.125rem;
}
.peer:placeholder-shown ~ .peer-placeholder-shown\:leading-dt-font-line-height-18 {
    line-height: 1.125rem;
}
.peer:focus ~ .peer-focus\:top-8 {
    top: 0.5rem;
}
.peer:focus ~ .peer-focus\:text-dt-font-size-12 {
    font-size: 0.75rem;
}
.peer:focus ~ .peer-focus\:leading-dt-font-line-height-16 {
    line-height: 1rem;
}
.peer:disabled ~ .peer-disabled\:text-dt-theme-text-password-input-field-password-input-field-disabled {
    color: var(--dt-theme-text-password-input-field-password-input-field-disabled);
}
.peer:disabled ~ .peer-disabled\:text-dt-theme-text-text-input-field-text-input-field-disabled {
    color: var(--dt-theme-text-text-input-field-text-input-field-disabled);
}
.has-\[\:disabled\]\:border-dt-theme-border-number-access-input-field-number-access-input-field-disabled:has(:disabled) {
    border-color: var(--dt-theme-border-number-access-input-field-number-access-input-field-disabled);
}
.has-\[\:disabled\]\:border-dt-theme-border-password-input-field-password-input-field-disabled:has(:disabled) {
    border-color: var(--dt-theme-border-password-input-field-password-input-field-disabled);
}
.has-\[\:disabled\]\:border-dt-theme-border-text-input-field-text-input-field-disabled:has(:disabled) {
    border-color: var(--dt-theme-border-text-input-field-text-input-field-disabled);
}
.has-\[\:disabled\]\:border-dt-theme-border-text-input-field-text-input-field-error-disabled:has(:disabled) {
    border-color: var(--dt-theme-border-text-input-field-text-input-field-error-disabled);
}
.has-\[\:disabled\]\:border-dt-theme-border-text-input-field-text-input-field-success-disabled:has(:disabled) {
    border-color: var(--dt-theme-border-text-input-field-text-input-field-success-disabled);
}
.has-\[\:disabled\]\:border-dt-theme-border-text-input-field-text-input-field-warning-disabled:has(:disabled) {
    border-color: var(--dt-theme-border-text-input-field-text-input-field-warning-disabled);
}
.has-\[\:disabled\]\:bg-dt-theme-surface-number-access-input-field-number-access-input-field-disabled:has(:disabled) {
    background-color: var(--dt-theme-surface-number-access-input-field-number-access-input-field-disabled);
}
.has-\[\:disabled\]\:bg-dt-theme-surface-password-input-field-password-input-field-disabled:has(:disabled) {
    background-color: var(--dt-theme-surface-password-input-field-password-input-field-disabled);
}
.has-\[\:disabled\]\:bg-dt-theme-surface-text-input-field-text-input-field-disabled:has(:disabled) {
    background-color: var(--dt-theme-surface-text-input-field-text-input-field-disabled);
}
.has-\[\:disabled\]\:opacity-dt-opacity-50:has(:disabled) {
    opacity: 50%;
}
.has-\[\:disabled\]\:before\:bg-dt-theme-surface-number-access-input-field-number-access-input-field-disabled:has(:disabled)::before {
    content: var(--tw-content);
    background-color: var(--dt-theme-surface-number-access-input-field-number-access-input-field-disabled);
}
.has-\[\:-moz-placeholder\]\:before\:content-\[\]:has(:-moz-placeholder)::before {
    --tw-content: ;
    content: var(--tw-content);
}
.has-\[\:placeholder-shown\]\:before\:content-\[\]:has(:placeholder-shown)::before {
    --tw-content: ;
    content: var(--tw-content);
}
.has-\[\:disabled\]\:after\:bg-dt-theme-text-number-access-input-field-number-access-input-field-disabled:has(:disabled)::after {
    content: var(--tw-content);
    background-color: var(--dt-theme-text-number-access-input-field-number-access-input-field-disabled);
}
.has-\[\:-moz-placeholder\]\:after\:content-\[\]:has(:-moz-placeholder)::after {
    --tw-content: ;
    content: var(--tw-content);
}
.has-\[\:placeholder-shown\]\:after\:content-\[\]:has(:placeholder-shown)::after {
    --tw-content: ;
    content: var(--tw-content);
}
.group:has(:disabled) .group-has-\[\:disabled\]\:cursor-default {
    cursor: default;
}
.group:has(:-moz-placeholder) .group-has-\[\:-moz-placeholder\]\:cursor-default {
    cursor: default;
}
.group:has(:placeholder-shown) .group-has-\[\:placeholder-shown\]\:cursor-default {
    cursor: default;
}
.group:has(:-moz-placeholder) .group-has-\[\:-moz-placeholder\]\:opacity-dt-opacity-50 {
    opacity: 50%;
}
.group:has(:placeholder-shown) .group-has-\[\:placeholder-shown\]\:opacity-dt-opacity-50 {
    opacity: 50%;
}
.data-\[isvalid\=false\]\:fill-dt-theme-icon-password-input-field-icon-error[data-isvalid="false"] {
    fill: var(--dt-theme-icon-password-input-field-icon-error);
}
.data-\[isvalid\=false\]\:fill-dt-theme-tv-icon-password-input-icon-cross[data-isvalid="false"] {
    fill: var(--dt-theme-tv-icon-password-input-icon-cross);
}
.data-\[isvalid\=true\]\:fill-dt-theme-icon-password-input-field-icon-success[data-isvalid="true"] {
    fill: var(--dt-theme-icon-password-input-field-icon-success);
}
.data-\[isvalid\=true\]\:fill-dt-theme-tv-icon-password-input-icon-check[data-isvalid="true"] {
    fill: var(--dt-theme-tv-icon-password-input-icon-check);
}
.tv-focus-self\:scale-tv-focus:focus {
    --tw-scale-x: 1.04;
    --tw-scale-y: 1.04;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tv-focus-self\:rounded-dt-radius-xlarge:focus {
    border-radius: 0.5rem;
}
.tv-focus-self\:border-none:focus {
    border-style: none;
}
.tv-focus-self\:border-dt-theme-tv-surface-list-list-focus:focus {
    border-color: var(--dt-theme-tv-surface-list-list-focus);
}
.tv-focus-self\:bg-dt-theme-tv-background-button-button-focus:focus {
    background-color: var(--dt-theme-tv-background-button-button-focus);
}
.tv-focus-self\:bg-dt-theme-tv-background-list-timeline-vote-list-timeline-vote-focus:focus {
    background-color: var(--dt-theme-tv-background-list-timeline-vote-list-timeline-vote-focus);
}
.tv-focus-self\:bg-dt-theme-tv-background-list-timeline-vote-list-timeline-vote-success:focus {
    background-color: var(--dt-theme-tv-background-list-timeline-vote-list-timeline-vote-success);
}
.tv-focus-self\:bg-dt-theme-tv-background-season-selector-season-selector-focus:focus {
    background-color: var(--dt-theme-tv-background-season-selector-season-selector-focus);
}
.tv-focus-self\:bg-dt-theme-tv-surface-list-list-focus:focus {
    background-color: var(--dt-theme-tv-surface-list-list-focus);
}
.tv-focus-self\:bg-dt-theme-tv-surface-list-ranking-list-ranking-focus:focus {
    background-color: var(--dt-theme-tv-surface-list-ranking-list-ranking-focus);
}
.tv-focus-self\:bg-dt-theme-tv-surface-list-statistics-list-statistics-focus:focus {
    background-color: var(--dt-theme-tv-surface-list-statistics-list-statistics-focus);
}
.tv-focus-self\:text-dt-theme-tv-text-season-selector-season-selector-focus:focus {
    color: var(--dt-theme-tv-text-season-selector-season-selector-focus);
}
.tv-focus-self\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.tv-focus-self\:outline:focus {
    outline-style: solid;
}
.tv-focus-self\:ring-dt-theme-tv-border-focus:focus {
    --tw-ring-color: var(--dt-theme-tv-border-focus);
}
.tv-focus-self\:ring-fallback-4:focus {
    box-shadow: var(--dt-theme-tv-border-focus) 0 0 0 var(--dt-border-medium);
  }
.tv-focus-self\:ring-dt-theme-tv-border-focus:focus {
    box-shadow: var(--dt-theme-tv-border-focus) 0 0 0 4px;
  }
.tv-focus-self\:after\:bg-dt-theme-tv-icon-button-icon-icon-alert-focus:focus::after {
    content: var(--tw-content);
    background-color: var(--dt-theme-tv-icon-button-icon-icon-alert-focus);
}
.tv-focus-self\:scale-tv-focus[data-tv-focus] {
    --tw-scale-x: 1.04;
    --tw-scale-y: 1.04;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tv-focus-self\:rounded-dt-radius-xlarge[data-tv-focus] {
    border-radius: 0.5rem;
}
.tv-focus-self\:border-none[data-tv-focus] {
    border-style: none;
}
.tv-focus-self\:border-dt-theme-tv-surface-list-list-focus[data-tv-focus] {
    border-color: var(--dt-theme-tv-surface-list-list-focus);
}
.tv-focus-self\:bg-dt-theme-tv-background-button-button-focus[data-tv-focus] {
    background-color: var(--dt-theme-tv-background-button-button-focus);
}
.tv-focus-self\:bg-dt-theme-tv-background-list-timeline-vote-list-timeline-vote-focus[data-tv-focus] {
    background-color: var(--dt-theme-tv-background-list-timeline-vote-list-timeline-vote-focus);
}
.tv-focus-self\:bg-dt-theme-tv-background-list-timeline-vote-list-timeline-vote-success[data-tv-focus] {
    background-color: var(--dt-theme-tv-background-list-timeline-vote-list-timeline-vote-success);
}
.tv-focus-self\:bg-dt-theme-tv-background-season-selector-season-selector-focus[data-tv-focus] {
    background-color: var(--dt-theme-tv-background-season-selector-season-selector-focus);
}
.tv-focus-self\:bg-dt-theme-tv-surface-list-list-focus[data-tv-focus] {
    background-color: var(--dt-theme-tv-surface-list-list-focus);
}
.tv-focus-self\:bg-dt-theme-tv-surface-list-ranking-list-ranking-focus[data-tv-focus] {
    background-color: var(--dt-theme-tv-surface-list-ranking-list-ranking-focus);
}
.tv-focus-self\:bg-dt-theme-tv-surface-list-statistics-list-statistics-focus[data-tv-focus] {
    background-color: var(--dt-theme-tv-surface-list-statistics-list-statistics-focus);
}
.tv-focus-self\:text-dt-theme-tv-text-season-selector-season-selector-focus[data-tv-focus] {
    color: var(--dt-theme-tv-text-season-selector-season-selector-focus);
}
.tv-focus-self\:outline-none[data-tv-focus] {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.tv-focus-self\:outline[data-tv-focus] {
    outline-style: solid;
}
.tv-focus-self\:ring-dt-theme-tv-border-focus[data-tv-focus] {
    --tw-ring-color: var(--dt-theme-tv-border-focus);
}
.tv-focus-self\:ring-fallback-4[data-tv-focus] {
    box-shadow: var(--dt-theme-tv-border-focus) 0 0 0 var(--dt-border-medium);
  }
.tv-focus-self\:ring-dt-theme-tv-border-focus[data-tv-focus] {
    box-shadow: var(--dt-theme-tv-border-focus) 0 0 0 4px;
  }
.tv-focus-self\:after\:bg-dt-theme-tv-icon-button-icon-icon-alert-focus[data-tv-focus]::after {
    content: var(--tw-content);
    background-color: var(--dt-theme-tv-icon-button-icon-icon-alert-focus);
}
.tv-focus-self\:scale-tv-focus.tv__focus {
    --tw-scale-x: 1.04;
    --tw-scale-y: 1.04;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tv-focus-self\:rounded-dt-radius-xlarge.tv__focus {
    border-radius: 0.5rem;
}
.tv-focus-self\:border-none.tv__focus {
    border-style: none;
}
.tv-focus-self\:border-dt-theme-tv-surface-list-list-focus.tv__focus {
    border-color: var(--dt-theme-tv-surface-list-list-focus);
}
.tv-focus-self\:bg-dt-theme-tv-background-button-button-focus.tv__focus {
    background-color: var(--dt-theme-tv-background-button-button-focus);
}
.tv-focus-self\:bg-dt-theme-tv-background-list-timeline-vote-list-timeline-vote-focus.tv__focus {
    background-color: var(--dt-theme-tv-background-list-timeline-vote-list-timeline-vote-focus);
}
.tv-focus-self\:bg-dt-theme-tv-background-list-timeline-vote-list-timeline-vote-success.tv__focus {
    background-color: var(--dt-theme-tv-background-list-timeline-vote-list-timeline-vote-success);
}
.tv-focus-self\:bg-dt-theme-tv-background-season-selector-season-selector-focus.tv__focus {
    background-color: var(--dt-theme-tv-background-season-selector-season-selector-focus);
}
.tv-focus-self\:bg-dt-theme-tv-surface-list-list-focus.tv__focus {
    background-color: var(--dt-theme-tv-surface-list-list-focus);
}
.tv-focus-self\:bg-dt-theme-tv-surface-list-ranking-list-ranking-focus.tv__focus {
    background-color: var(--dt-theme-tv-surface-list-ranking-list-ranking-focus);
}
.tv-focus-self\:bg-dt-theme-tv-surface-list-statistics-list-statistics-focus.tv__focus {
    background-color: var(--dt-theme-tv-surface-list-statistics-list-statistics-focus);
}
.tv-focus-self\:text-dt-theme-tv-text-season-selector-season-selector-focus.tv__focus {
    color: var(--dt-theme-tv-text-season-selector-season-selector-focus);
}
.tv-focus-self\:outline-none.tv__focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.tv-focus-self\:outline.tv__focus {
    outline-style: solid;
}
.tv-focus-self\:ring-dt-theme-tv-border-focus.tv__focus {
    --tw-ring-color: var(--dt-theme-tv-border-focus);
}
.tv-focus-self\:ring-fallback-4.tv__focus {
    box-shadow: var(--dt-theme-tv-border-focus) 0 0 0 var(--dt-border-medium);
  }
.tv-focus-self\:ring-dt-theme-tv-border-focus.tv__focus {
    box-shadow: var(--dt-theme-tv-border-focus) 0 0 0 4px;
  }
.tv-focus-self\:after\:bg-dt-theme-tv-icon-button-icon-icon-alert-focus.tv__focus::after {
    content: var(--tw-content);
    background-color: var(--dt-theme-tv-icon-button-icon-icon-alert-focus);
}
[data-tv-focus] .tv-focus-parent\:size-40 {
    width: 2.5rem;
    height: 2.5rem;
}
[data-tv-focus] .tv-focus-parent\:scale-tv-focus {
    --tw-scale-x: 1.04;
    --tw-scale-y: 1.04;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
[data-tv-focus] .tv-focus-parent\:bg-dt-theme-tv-background-button-icon-button-icon-focus {
    background-color: var(--dt-theme-tv-background-button-icon-button-icon-focus);
}
[data-tv-focus] .tv-focus-parent\:bg-dt-theme-tv-surface-card-timeline-facts-card-timeline-facts-focus {
    background-color: var(--dt-theme-tv-surface-card-timeline-facts-card-timeline-facts-focus);
}
[data-tv-focus] .tv-focus-parent\:fill-dt-theme-tv-icon-button-icon-focus {
    fill: var(--dt-theme-tv-icon-button-icon-focus);
}
[data-tv-focus] .tv-focus-parent\:fill-dt-theme-tv-icon-list-icon-focus {
    fill: var(--dt-theme-tv-icon-list-icon-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-avatar-avatar-focus {
    color: var(--dt-theme-tv-text-avatar-avatar-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-button-button-focus {
    color: var(--dt-theme-tv-text-button-button-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-card-timeline-facts-body-focus {
    color: var(--dt-theme-tv-text-card-timeline-facts-body-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-label-focus {
    color: var(--dt-theme-tv-text-list-list-row-label-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-promotion-focus {
    color: var(--dt-theme-tv-text-list-list-row-promotion-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-sublabel-focus {
    color: var(--dt-theme-tv-text-list-list-row-sublabel-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-subtitle-focus {
    color: var(--dt-theme-tv-text-list-list-row-subtitle-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-title-focus {
    color: var(--dt-theme-tv-text-list-list-row-title-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-number-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-number-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-point-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-point-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-title-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-title-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-primary-data-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-primary-data-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-secondary-data-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-secondary-data-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-subtitle-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-subtitle-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-title-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-title-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-description-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-description-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-subtitle-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-subtitle-focus);
}
[data-tv-focus] .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-title-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-title-focus);
}
[data-tv-focus] .tv-focus-parent\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
[data-tv-focus] .tv-focus-parent\:outline {
    outline-style: solid;
}
[data-tv-focus] .tv-focus-parent\:outline-dt-border-medium {
    outline-width: 0.25rem;
}
[data-tv-focus] .tv-focus-parent\:outline-dt-theme-tv-border-focus {
    outline-color: var(--dt-theme-tv-border-focus);
}
[data-tv-focus] .tv-focus-parent\:ring-fallback-4 {
    box-shadow: var(--dt-theme-tv-border-focus) 0 0 0 var(--dt-border-medium);
  }
.tv__focus .tv-focus-parent\:size-40 {
    width: 2.5rem;
    height: 2.5rem;
}
.tv__focus .tv-focus-parent\:scale-tv-focus {
    --tw-scale-x: 1.04;
    --tw-scale-y: 1.04;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tv__focus .tv-focus-parent\:bg-dt-theme-tv-background-button-icon-button-icon-focus {
    background-color: var(--dt-theme-tv-background-button-icon-button-icon-focus);
}
.tv__focus .tv-focus-parent\:bg-dt-theme-tv-surface-card-timeline-facts-card-timeline-facts-focus {
    background-color: var(--dt-theme-tv-surface-card-timeline-facts-card-timeline-facts-focus);
}
.tv__focus .tv-focus-parent\:fill-dt-theme-tv-icon-button-icon-focus {
    fill: var(--dt-theme-tv-icon-button-icon-focus);
}
.tv__focus .tv-focus-parent\:fill-dt-theme-tv-icon-list-icon-focus {
    fill: var(--dt-theme-tv-icon-list-icon-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-avatar-avatar-focus {
    color: var(--dt-theme-tv-text-avatar-avatar-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-button-button-focus {
    color: var(--dt-theme-tv-text-button-button-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-card-timeline-facts-body-focus {
    color: var(--dt-theme-tv-text-card-timeline-facts-body-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-label-focus {
    color: var(--dt-theme-tv-text-list-list-row-label-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-promotion-focus {
    color: var(--dt-theme-tv-text-list-list-row-promotion-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-sublabel-focus {
    color: var(--dt-theme-tv-text-list-list-row-sublabel-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-subtitle-focus {
    color: var(--dt-theme-tv-text-list-list-row-subtitle-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-title-focus {
    color: var(--dt-theme-tv-text-list-list-row-title-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-number-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-number-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-point-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-point-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-title-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-title-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-primary-data-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-primary-data-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-secondary-data-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-secondary-data-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-subtitle-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-subtitle-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-title-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-title-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-description-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-description-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-subtitle-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-subtitle-focus);
}
.tv__focus .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-title-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-title-focus);
}
.tv__focus .tv-focus-parent\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.tv__focus .tv-focus-parent\:outline {
    outline-style: solid;
}
.tv__focus .tv-focus-parent\:outline-dt-border-medium {
    outline-width: 0.25rem;
}
.tv__focus .tv-focus-parent\:outline-dt-theme-tv-border-focus {
    outline-color: var(--dt-theme-tv-border-focus);
}
.tv__focus .tv-focus-parent\:ring-fallback-4 {
    box-shadow: var(--dt-theme-tv-border-focus) 0 0 0 var(--dt-border-medium);
  }
[data-tv-focusable="true"]:focus .tv-focus-parent\:size-40 {
    width: 2.5rem;
    height: 2.5rem;
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:scale-tv-focus {
    --tw-scale-x: 1.04;
    --tw-scale-y: 1.04;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:bg-dt-theme-tv-background-button-icon-button-icon-focus {
    background-color: var(--dt-theme-tv-background-button-icon-button-icon-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:bg-dt-theme-tv-surface-card-timeline-facts-card-timeline-facts-focus {
    background-color: var(--dt-theme-tv-surface-card-timeline-facts-card-timeline-facts-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:fill-dt-theme-tv-icon-button-icon-focus {
    fill: var(--dt-theme-tv-icon-button-icon-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:fill-dt-theme-tv-icon-list-icon-focus {
    fill: var(--dt-theme-tv-icon-list-icon-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-avatar-avatar-focus {
    color: var(--dt-theme-tv-text-avatar-avatar-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-button-button-focus {
    color: var(--dt-theme-tv-text-button-button-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-card-timeline-facts-body-focus {
    color: var(--dt-theme-tv-text-card-timeline-facts-body-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-label-focus {
    color: var(--dt-theme-tv-text-list-list-row-label-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-promotion-focus {
    color: var(--dt-theme-tv-text-list-list-row-promotion-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-sublabel-focus {
    color: var(--dt-theme-tv-text-list-list-row-sublabel-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-subtitle-focus {
    color: var(--dt-theme-tv-text-list-list-row-subtitle-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-title-focus {
    color: var(--dt-theme-tv-text-list-list-row-title-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-number-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-number-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-point-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-point-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-title-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-title-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-primary-data-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-primary-data-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-secondary-data-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-secondary-data-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-subtitle-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-subtitle-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-title-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-title-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-description-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-description-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-subtitle-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-subtitle-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-title-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-title-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:outline {
    outline-style: solid;
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:outline-dt-border-medium {
    outline-width: 0.25rem;
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:outline-dt-theme-tv-border-focus {
    outline-color: var(--dt-theme-tv-border-focus);
}
[data-tv-focusable="true"]:focus .tv-focus-parent\:ring-fallback-4 {
    box-shadow: var(--dt-theme-tv-border-focus) 0 0 0 var(--dt-border-medium);
  }
button:enabled:focus .tv-focus-parent\:size-40 {
    width: 2.5rem;
    height: 2.5rem;
}
button:enabled:focus .tv-focus-parent\:scale-tv-focus {
    --tw-scale-x: 1.04;
    --tw-scale-y: 1.04;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
button:enabled:focus .tv-focus-parent\:bg-dt-theme-tv-background-button-icon-button-icon-focus {
    background-color: var(--dt-theme-tv-background-button-icon-button-icon-focus);
}
button:enabled:focus .tv-focus-parent\:bg-dt-theme-tv-surface-card-timeline-facts-card-timeline-facts-focus {
    background-color: var(--dt-theme-tv-surface-card-timeline-facts-card-timeline-facts-focus);
}
button:enabled:focus .tv-focus-parent\:fill-dt-theme-tv-icon-button-icon-focus {
    fill: var(--dt-theme-tv-icon-button-icon-focus);
}
button:enabled:focus .tv-focus-parent\:fill-dt-theme-tv-icon-list-icon-focus {
    fill: var(--dt-theme-tv-icon-list-icon-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-avatar-avatar-focus {
    color: var(--dt-theme-tv-text-avatar-avatar-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-button-button-focus {
    color: var(--dt-theme-tv-text-button-button-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-card-timeline-facts-body-focus {
    color: var(--dt-theme-tv-text-card-timeline-facts-body-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-label-focus {
    color: var(--dt-theme-tv-text-list-list-row-label-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-promotion-focus {
    color: var(--dt-theme-tv-text-list-list-row-promotion-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-sublabel-focus {
    color: var(--dt-theme-tv-text-list-list-row-sublabel-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-subtitle-focus {
    color: var(--dt-theme-tv-text-list-list-row-subtitle-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-title-focus {
    color: var(--dt-theme-tv-text-list-list-row-title-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-number-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-number-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-point-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-point-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-title-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-title-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-primary-data-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-primary-data-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-secondary-data-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-secondary-data-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-subtitle-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-subtitle-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-title-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-title-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-description-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-description-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-subtitle-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-subtitle-focus);
}
button:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-title-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-title-focus);
}
button:enabled:focus .tv-focus-parent\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
button:enabled:focus .tv-focus-parent\:outline {
    outline-style: solid;
}
button:enabled:focus .tv-focus-parent\:outline-dt-border-medium {
    outline-width: 0.25rem;
}
button:enabled:focus .tv-focus-parent\:outline-dt-theme-tv-border-focus {
    outline-color: var(--dt-theme-tv-border-focus);
}
button:enabled:focus .tv-focus-parent\:ring-fallback-4 {
    box-shadow: var(--dt-theme-tv-border-focus) 0 0 0 var(--dt-border-medium);
  }
input:enabled:focus .tv-focus-parent\:size-40 {
    width: 2.5rem;
    height: 2.5rem;
}
input:enabled:focus .tv-focus-parent\:scale-tv-focus {
    --tw-scale-x: 1.04;
    --tw-scale-y: 1.04;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
input:enabled:focus .tv-focus-parent\:bg-dt-theme-tv-background-button-icon-button-icon-focus {
    background-color: var(--dt-theme-tv-background-button-icon-button-icon-focus);
}
input:enabled:focus .tv-focus-parent\:bg-dt-theme-tv-surface-card-timeline-facts-card-timeline-facts-focus {
    background-color: var(--dt-theme-tv-surface-card-timeline-facts-card-timeline-facts-focus);
}
input:enabled:focus .tv-focus-parent\:fill-dt-theme-tv-icon-button-icon-focus {
    fill: var(--dt-theme-tv-icon-button-icon-focus);
}
input:enabled:focus .tv-focus-parent\:fill-dt-theme-tv-icon-list-icon-focus {
    fill: var(--dt-theme-tv-icon-list-icon-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-avatar-avatar-focus {
    color: var(--dt-theme-tv-text-avatar-avatar-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-button-button-focus {
    color: var(--dt-theme-tv-text-button-button-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-card-timeline-facts-body-focus {
    color: var(--dt-theme-tv-text-card-timeline-facts-body-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-label-focus {
    color: var(--dt-theme-tv-text-list-list-row-label-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-promotion-focus {
    color: var(--dt-theme-tv-text-list-list-row-promotion-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-sublabel-focus {
    color: var(--dt-theme-tv-text-list-list-row-sublabel-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-subtitle-focus {
    color: var(--dt-theme-tv-text-list-list-row-subtitle-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-title-focus {
    color: var(--dt-theme-tv-text-list-list-row-title-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-number-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-number-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-point-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-point-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-title-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-title-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-primary-data-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-primary-data-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-secondary-data-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-secondary-data-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-subtitle-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-subtitle-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-title-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-title-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-description-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-description-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-subtitle-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-subtitle-focus);
}
input:enabled:focus .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-title-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-title-focus);
}
input:enabled:focus .tv-focus-parent\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
input:enabled:focus .tv-focus-parent\:outline {
    outline-style: solid;
}
input:enabled:focus .tv-focus-parent\:outline-dt-border-medium {
    outline-width: 0.25rem;
}
input:enabled:focus .tv-focus-parent\:outline-dt-theme-tv-border-focus {
    outline-color: var(--dt-theme-tv-border-focus);
}
input:enabled:focus .tv-focus-parent\:ring-fallback-4 {
    box-shadow: var(--dt-theme-tv-border-focus) 0 0 0 var(--dt-border-medium);
  }
a:focus .tv-focus-parent\:size-40 {
    width: 2.5rem;
    height: 2.5rem;
}
a:focus .tv-focus-parent\:scale-tv-focus {
    --tw-scale-x: 1.04;
    --tw-scale-y: 1.04;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
a:focus .tv-focus-parent\:bg-dt-theme-tv-background-button-icon-button-icon-focus {
    background-color: var(--dt-theme-tv-background-button-icon-button-icon-focus);
}
a:focus .tv-focus-parent\:bg-dt-theme-tv-surface-card-timeline-facts-card-timeline-facts-focus {
    background-color: var(--dt-theme-tv-surface-card-timeline-facts-card-timeline-facts-focus);
}
a:focus .tv-focus-parent\:fill-dt-theme-tv-icon-button-icon-focus {
    fill: var(--dt-theme-tv-icon-button-icon-focus);
}
a:focus .tv-focus-parent\:fill-dt-theme-tv-icon-list-icon-focus {
    fill: var(--dt-theme-tv-icon-list-icon-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-avatar-avatar-focus {
    color: var(--dt-theme-tv-text-avatar-avatar-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-button-button-focus {
    color: var(--dt-theme-tv-text-button-button-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-card-timeline-facts-body-focus {
    color: var(--dt-theme-tv-text-card-timeline-facts-body-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-label-focus {
    color: var(--dt-theme-tv-text-list-list-row-label-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-promotion-focus {
    color: var(--dt-theme-tv-text-list-list-row-promotion-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-sublabel-focus {
    color: var(--dt-theme-tv-text-list-list-row-sublabel-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-subtitle-focus {
    color: var(--dt-theme-tv-text-list-list-row-subtitle-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-list-list-row-title-focus {
    color: var(--dt-theme-tv-text-list-list-row-title-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-number-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-number-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-point-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-point-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-list-ranking-list-ranking-title-focus {
    color: var(--dt-theme-tv-text-list-ranking-list-ranking-title-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-primary-data-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-primary-data-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-secondary-data-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-secondary-data-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-subtitle-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-subtitle-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-list-statistics-list-statistics-title-focus {
    color: var(--dt-theme-tv-text-list-statistics-list-statistics-title-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-description-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-description-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-subtitle-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-subtitle-focus);
}
a:focus .tv-focus-parent\:text-dt-theme-tv-text-media-block-media-block-title-focus {
    color: var(--dt-theme-tv-text-media-block-media-block-title-focus);
}
a:focus .tv-focus-parent\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
a:focus .tv-focus-parent\:outline {
    outline-style: solid;
}
a:focus .tv-focus-parent\:outline-dt-border-medium {
    outline-width: 0.25rem;
}
a:focus .tv-focus-parent\:outline-dt-theme-tv-border-focus {
    outline-color: var(--dt-theme-tv-border-focus);
}
a:focus .tv-focus-parent\:ring-fallback-4 {
    box-shadow: var(--dt-theme-tv-border-focus) 0 0 0 var(--dt-border-medium);
  }
.not-last\:mb-dt-spacing-100:not(:last-child) {
    margin-bottom: 0.5rem;
}
.not-last\:mb-dt-spacing-25:not(:last-child) {
    margin-bottom: 0.125rem;
}
@media (min-width: 0px) {
    .dt-breakpoint-xs-start\:px-dt-spacing-200 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
@media (min-width: 375px) {
    .dt-breakpoint-xs\:ml-auto {
        margin-left: auto;
    }
    .dt-breakpoint-xs\:mt-dt-spacing-none {
        margin-top: 0;
    }
    .dt-breakpoint-xs\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .dt-breakpoint-xs\:flex-row {
        flex-direction: row;
    }
    .dt-breakpoint-xs\:items-center {
        align-items: center;
    }
    .dt-breakpoint-xs\:pl-dt-spacing-200 {
        padding-left: 1rem;
    }
}
@media (min-width: 700px) {
    .dt-breakpoint-sm-start\:sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
    }
    .dt-breakpoint-sm-start\:not-sr-only {
        position: static;
        width: auto;
        height: auto;
        padding: 0;
        margin: 0;
        overflow: visible;
        clip: auto;
        white-space: normal;
    }
    .dt-breakpoint-sm-start\:static {
        position: static;
    }
    .dt-breakpoint-sm-start\:inset-y-auto {
        top: auto;
        bottom: auto;
    }
    .dt-breakpoint-sm-start\:order-2 {
        order: 2;
    }
    .dt-breakpoint-sm-start\:order-3 {
        order: 3;
    }
    .dt-breakpoint-sm-start\:\!mx-auto {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .dt-breakpoint-sm-start\:\!mx-dt-spacing-none {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .dt-breakpoint-sm-start\:mb-dt-spacing-75 {
        margin-bottom: 0.375rem;
    }
    .dt-breakpoint-sm-start\:ml-dt-spacing-200 {
        margin-left: 1rem;
    }
    .dt-breakpoint-sm-start\:ml-dt-spacing-50 {
        margin-left: 0.25rem;
    }
    .dt-breakpoint-sm-start\:mt-dt-spacing-none {
        margin-top: 0;
    }
    .dt-breakpoint-sm-start\:block {
        display: block;
    }
    .dt-breakpoint-sm-start\:flex {
        display: flex;
    }
    .dt-breakpoint-sm-start\:hidden {
        display: none;
    }
    .dt-breakpoint-sm-start\:size-auto {
        width: auto;
        height: auto;
    }
    .dt-breakpoint-sm-start\:\!h-22 {
        height: 1.375rem !important;
    }
    .dt-breakpoint-sm-start\:\!h-32 {
        height: 2rem !important;
    }
    .dt-breakpoint-sm-start\:h-32 {
        height: 2rem;
    }
    .dt-breakpoint-sm-start\:min-h-22 {
        min-height: 1.375rem;
    }
    .dt-breakpoint-sm-start\:w-1\/2 {
        width: 50%;
    }
    .dt-breakpoint-sm-start\:w-64 {
        width: 4rem;
    }
    .dt-breakpoint-sm-start\:w-\[10rem\] {
        width: 10rem;
    }
    .dt-breakpoint-sm-start\:w-\[20rem\] {
        width: 20rem;
    }
    .dt-breakpoint-sm-start\:w-auto {
        width: auto;
    }
    .dt-breakpoint-sm-start\:w-full {
        width: 100%;
    }
    .dt-breakpoint-sm-start\:min-w-22 {
        min-width: 1.375rem;
    }
    .dt-breakpoint-sm-start\:min-w-\[39\.3125rem\] {
        min-width: 39.3125rem;
    }
    .dt-breakpoint-sm-start\:min-w-\[3rem\] {
        min-width: 3rem;
    }
    .dt-breakpoint-sm-start\:min-w-fit {
        min-width: -moz-fit-content;
        min-width: fit-content;
    }
    .dt-breakpoint-sm-start\:max-w-\[20rem\] {
        max-width: 20rem;
    }
    .dt-breakpoint-sm-start\:max-w-\[39\.3125rem\] {
        max-width: 39.3125rem;
    }
    .dt-breakpoint-sm-start\:flex-initial {
        flex: 0 1 auto;
    }
    .dt-breakpoint-sm-start\:shrink-0 {
        flex-shrink: 0;
    }
    .dt-breakpoint-sm-start\:grow {
        flex-grow: 1;
    }
    .dt-breakpoint-sm-start\:\!basis-22 {
        flex-basis: 1.375rem !important;
    }
    .dt-breakpoint-sm-start\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .dt-breakpoint-sm-start\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .dt-breakpoint-sm-start\:flex-row {
        flex-direction: row;
    }
    .dt-breakpoint-sm-start\:flex-col {
        flex-direction: column;
    }
    .dt-breakpoint-sm-start\:flex-nowrap {
        flex-wrap: nowrap;
    }
    .dt-breakpoint-sm-start\:items-start {
        align-items: flex-start;
    }
    .dt-breakpoint-sm-start\:items-stretch {
        align-items: stretch;
    }
    .dt-breakpoint-sm-start\:space-x-dt-spacing-none > :not([hidden]) ~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0px * var(--tw-space-x-reverse));
        margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
    }
    .dt-breakpoint-sm-start\:space-y-dt-spacing-100 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
    }
    .dt-breakpoint-sm-start\:self-center {
        align-self: center;
    }
    .dt-breakpoint-sm-start\:text-clip {
        text-overflow: clip;
    }
    .dt-breakpoint-sm-start\:whitespace-nowrap {
        white-space: nowrap;
    }
    .dt-breakpoint-sm-start\:rounded-dt-radius-large {
        border-radius: 0.375rem;
    }
    .dt-breakpoint-sm-start\:px-dt-spacing-200 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .dt-breakpoint-sm-start\:px-dt-spacing-300 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .dt-breakpoint-sm-start\:px-dt-spacing-none {
        padding-left: 0;
        padding-right: 0;
    }
    .dt-breakpoint-sm-start\:py-dt-spacing-200 {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .dt-breakpoint-sm-start\:py-dt-spacing-none {
        padding-top: 0;
        padding-bottom: 0;
    }
    .dt-breakpoint-sm-start\:pr-dt-spacing-250 {
        padding-right: 1.25rem;
    }
    .dt-breakpoint-sm-start\:pr-dt-spacing-400 {
        padding-right: 2rem;
    }
    .dt-breakpoint-sm-start\:pt-dt-spacing-100 {
        padding-top: 0.5rem;
    }
    .dt-breakpoint-sm-start\:pt-dt-spacing-none {
        padding-top: 0;
    }
    .dt-breakpoint-sm-start\:text-dt-font-size-16 {
        font-size: 1rem;
    }
    .dt-breakpoint-sm-start\:leading-dt-font-line-height-20 {
        line-height: 1.25rem;
    }
}
@media (min-width: 800px) {
    .dt-breakpoint-sm\:min-h-62 {
        min-height: 3.875rem;
    }
    .dt-breakpoint-sm\:w-\[5\.1875rem\] {
        width: 5.1875rem;
    }
}
@media (min-width: 960px) {
    .dt-breakpoint-md-start\:h-34 {
        height: 2.125rem;
    }
    .dt-breakpoint-md-start\:h-48 {
        height: 3rem;
    }
    .dt-breakpoint-md-start\:h-52 {
        height: 3.25rem;
    }
    .dt-breakpoint-md-start\:h-76 {
        height: 4.75rem;
    }
    .dt-breakpoint-md-start\:w-1\/3 {
        width: 33.333333%;
    }
    .dt-breakpoint-md-start\:w-\[11rem\] {
        width: 11rem;
    }
    .dt-breakpoint-md-start\:min-w-68 {
        min-width: 4.25rem;
    }
    .dt-breakpoint-md-start\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .dt-breakpoint-md-start\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .dt-breakpoint-md-start\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .dt-breakpoint-md-start\:space-x-dt-spacing-200 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1rem * var(--tw-space-x-reverse));
        margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
    }
    .dt-breakpoint-md-start\:text-dt-font-size-14 {
        font-size: 0.875rem;
    }
    .dt-breakpoint-md-start\:text-dt-font-size-16 {
        font-size: 1rem;
    }
    .dt-breakpoint-md-start\:text-dt-font-size-18 {
        font-size: 1.125rem;
    }
    .dt-breakpoint-md-start\:text-dt-font-size-20 {
        font-size: 1.25rem;
    }
    .dt-breakpoint-md-start\:leading-dt-font-line-height-20 {
        line-height: 1.25rem;
    }
    .dt-breakpoint-md-start\:leading-dt-font-line-height-22 {
        line-height: 1.375rem;
    }
    .dt-breakpoint-md-start\:leading-dt-font-line-height-24 {
        line-height: 1.5rem;
    }
    .before\:dt-breakpoint-md-start\:content-none::before {
        --tw-content: none;
        content: var(--tw-content);
    }
    .after\:dt-breakpoint-md-start\:content-none::after {
        --tw-content: none;
        content: var(--tw-content);
    }
}
@media (min-width: 1285px) {
    .dt-breakpoint-lg-start\:mb-dt-spacing-200 {
        margin-bottom: 1rem;
    }
    .dt-breakpoint-lg-start\:min-w-\[53rem\] {
        min-width: 53rem;
    }
    .dt-breakpoint-lg-start\:max-w-\[53rem\] {
        max-width: 53rem;
    }
    .dt-breakpoint-lg-start\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .dt-breakpoint-lg-start\:grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .dt-breakpoint-lg-start\:space-y-dt-spacing-200 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(1rem * var(--tw-space-y-reverse));
    }
    .dt-breakpoint-lg-start\:px-dt-spacing-300 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .dt-breakpoint-lg-start\:py-dt-spacing-300 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
}
@media (min-width: 1600px) {
    .dt-breakpoint-xlg-start\:min-w-\[59\.6875rem\] {
        min-width: 59.6875rem;
    }
    .dt-breakpoint-xlg-start\:max-w-\[59\.6875rem\] {
        max-width: 59.6875rem;
    }
    .dt-breakpoint-xlg-start\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .dt-breakpoint-xlg-start\:grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .dt-breakpoint-xlg-start\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
}
@media (min-width: 1920px) {
    .dt-breakpoint-xxlg-start\:min-w-\[73rem\] {
        min-width: 73rem;
    }
    .dt-breakpoint-xxlg-start\:max-w-\[73rem\] {
        max-width: 73rem;
    }
    .dt-breakpoint-xxlg-start\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .dt-breakpoint-xxlg-start\:grid-cols-7 {
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }
}
.\[\&\&\]\:items-end.\[\&\&\]\:items-end {
    align-items: flex-end;
}
.\[\&\:\:-webkit-details-marker\]\:hidden::-webkit-details-marker {
    display: none;
}
.\[\&\>\*\:first-child\]\:pl-dt-spacing-200>*:first-child {
    padding-left: 1rem;
}
@media (min-width: 700px) {
    .\[\&\>\*\:first-child\]\:dt-breakpoint-sm-start\:pl-dt-spacing-400>*:first-child {
        padding-left: 2rem;
    }
}
.\[\&\>\*\]\:flex>* {
    display: flex;
}
.\[\&\>\*\]\:flex-wrap>* {
    flex-wrap: wrap;
}
.\[\&\>\*\]\:gap-24>* {
    gap: 1.5rem;
}
.\[\&\>div\]\:flex>div {
    display: flex;
}
.\[\&\>div\]\:items-center>div {
    align-items: center;
}
.\[\&\>div\]\:justify-between>div {
    justify-content: space-between;
}
.\[\&\>div\]\:rounded-dt-radius-circle>div {
    border-radius: 6.25rem;
}
.\[\&\>p\]\:m-dt-spacing-none>p {
    margin: 0;
}
.\[\&\>p\]\:mb-dt-spacing-none>p {
    margin-bottom: 0;
}
.\[\&\>p\]\:overflow-hidden>p {
    overflow: hidden;
}
.\[\&\>p\]\:overflow-ellipsis>p {
    text-overflow: ellipsis;
}
.\[\&\>p\]\:whitespace-nowrap>p {
    white-space: nowrap;
}
.\[\&\>span\]\:pt-dt-spacing-none>span {
    padding-top: 0;
}
.\[\&\>span\]\:text-dt-font-size-16>span {
    font-size: 1rem;
}
.\[\&\>span\]\:leading-dt-font-line-height-20>span {
    line-height: 1.25rem;
}
@media (min-width: 700px) {
    .\[\&\>span\]\:dt-breakpoint-sm-start\:text-dt-font-size-18>span {
        font-size: 1.125rem;
    }
    .\[\&\>span\]\:dt-breakpoint-sm-start\:leading-dt-font-line-height-22>span {
        line-height: 1.375rem;
    }
}
.\[\&\>summary_svg\]\:open\:rotate-45[open]>summary svg {
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\[\&\>svg\]\:mt-dt-spacing-25>svg {
    margin-top: 0.125rem;
}
.\[\&\>svg\]\:size-16>svg {
    width: 1rem;
    height: 1rem;
}
.\[\&\>svg\]\:size-20>svg {
    width: 1.25rem;
    height: 1.25rem;
}
.\[\&\>svg\]\:size-36>svg {
    width: 2.25rem;
    height: 2.25rem;
}
.\[\&\>svg\]\:size-40>svg {
    width: 2.5rem;
    height: 2.5rem;
}
.\[\&\>svg\]\:size-56>svg {
    width: 3.5rem;
    height: 3.5rem;
}
.\[\&\>svg\]\:h-14>svg {
    height: 0.875rem;
}
.\[\&\>svg\]\:h-24>svg {
    height: 1.5rem;
}
.\[\&\>svg\]\:fill-dt-theme-text-text-100>svg {
    fill: var(--dt-theme-text-text-100);
}
.\[\&\]\:h-36 {
    height: 2.25rem;
}
.\[\&\]\:h-\[3\.875rem\] {
    height: 3.875rem;
}
.\[\&\]\:flex-nowrap {
    flex-wrap: nowrap;
}
.\[\&\]\:rounded-dt-radius-xlarge {
    border-radius: 0.5rem;
}
.\[\&\]\:bg-transparent {
    background-color: rgba(0, 0, 0, 0);
}
.\[\&\]\:fill-dt-theme-text-header-header-default {
    fill: var(--dt-theme-text-header-header-default);
}
.\[\&\]\:px-dt-spacing-100 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.\[\&\]\:text-dt-font-size-16 {
    font-size: 1rem;
}
.\[\&\]\:text-dt-font-size-32 {
    font-size: 2rem;
}
.\[\&\]\:font-dt-font-weight-semibold {
    font-weight: 600;
}
.\[\&\]\:leading-dt-font-line-height-20 {
    line-height: 1.25rem;
}
.\[\&\]\:leading-dt-font-line-height-38 {
    line-height: 2.375rem;
}
.\[\&\]\:text-dt-theme-text-header-header-active {
    color: var(--dt-theme-text-header-header-active);
}
.\[\&\]\:text-dt-theme-text-header-header-default {
    color: var(--dt-theme-text-header-header-default);
}
.\[\&\]\:transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.\[\&\]\:duration-200 {
    transition-duration: 200ms;
}
.group:hover .group-hover\:\[\&\]\:fill-dt-theme-text-header-header-active {
    fill: var(--dt-theme-text-header-header-active);
}
.group:hover .group-hover\:\[\&\]\:text-dt-theme-text-header-header-active {
    color: var(--dt-theme-text-header-header-active);
}
.\[\&_span\]\:font-dt-font-family-system span {
    font-family: Hind, Arial, sans-serif;
}
.\[\&_span\]\:font-dt-font-weight-semibold span {
    font-weight: 600;
}
