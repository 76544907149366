/*  TYPOGRAPHY
---------------------------------------------------*/

/* SETTINGS
-----------------------------------------------------------*/
*,
*::before,
*::after {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased; /* stylelint-disable-line property-no-unknown */
  text-rendering: optimizeLegibility;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

html {
  min-height: 100%;
  /*Set baseline font size to 16px, This is used as a baseline for rem (root ems) values */
  font-size: calc(
    1em * var(--viewport-resize-ratio)
  ); /* For IE11 to do the math properly, See http://bit.ly/1g4X0bX; */
  font-size: calc(var(--font-size-html) * var(--viewport-resize-ratio));

  /* If the workaround solution for IE11 does not work in every case, so we fix in 16px */
  @media (--mq-browser-ie) {
    font-size: 16px;
  }
}

body {
  position: relative;
  font-family: var(--font_hind);
  font-style: var(--base-font-style);
  font-weight: var(--base-font-weight);
  line-height: 1.4;
  color: var(--color_primary);
  background: var(--background-page);

  &:fullscreen {
    width: 100%;
  }
}

/* Get rid of gap under images by making them display: inline-block; */

img {
  display: inline-block;
  vertical-align: middle;
}

ul,
ol,
dl {
  padding: 0;
  margin: 0;
}

ul,
ol {
  list-style: none;
}

/* Links
// ----------------------------------------------------------- */
a {
  line-height: inherit;
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  img {
    border: 0;
  }
}

/* Buttons & interactive elements
// ----------------------------------------------------------- */
button {
  display: inline-block;
  padding: 0;
  font: inherit;
  vertical-align: middle;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  border: 0;
}

/* Code
// ----------------------------------------------------------- */
pre,
code {
  font-family: 'Lucida Console', Consolas, Monaco, monospace;
}

/* Global font-weight
// ----------------------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
strong,
b {
  font-family: var(--font_hind);
  font-weight: 600;
}

/* Fonts
// ----------------------------------------------------------- */
@mixin importFont TextSecurity, text-security-disc, normal;
