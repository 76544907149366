/*  GENERAL
---------------------------------------------------*/

/* SETTINGS
-----------------------------------------------------------*/
:global {
  .embed {
    body,
    #application {
      height: auto;
    }
  }

  .webview {
    body,
    #application {
      height: 90%;
    }
  }
}
