/* GLOBALS
 *
 * These are globally available css classes that will
 * not be "modulized" by css modules and thus can be
 * used to render styled component created by the BO
---------------------------------------------------*/

:global {
  .button-primary {
    padding: 0.75rem;
    margin: 0;
    font-family: var(--font_hind);
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--color_button-fg);
    background-color: var(--color_secondary);
    border: 0;
    border-radius: 0.125rem;
    transition: background-color 200ms ease-out;

    &:hover,
    &:focus {
      cursor: pointer;
      background-color: var(--color_link-hover);
    }
  }

  .noScrollBar {
    @mixin noScrollBar;
  }

  .noA11yFocus {
    a,
    button,
    [tabIndex],
    iframe,
    input,
    select,
    textarea {
      &:focus {
        outline: none;
      }
    }
  }

  .HorizontalList {
    @mixin deviceFamily playstation {
      overflow: hidden;
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  white-space: nowrap;
  border-width: 0;
  clip: rect(0, 0, 0, 0);
}
