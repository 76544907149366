/*  GENERAL
---------------------------------------------------*/

/* SETTINGS
-----------------------------------------------------------*/
html {
  -webkit-font-smoothing: antialiased;
}

:global {
  .no-js .cover {
    visibility: visible;
    opacity: 1 !important;
  }

  #application {
    height: 100%;
  }

  .noscroll,
  body[class*='noscroll-'] {
    overflow: hidden;
  }
}
