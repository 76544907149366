@define-mixin noScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  :global(html.device-playstation) & {
    overflow: hidden;
  }
}
