@font-face {
  font-family: 'Canal';
  font-weight: 700;
  src:
    url('./CanalBoldItalic.woff2') format('woff2'),
    url('./CanalBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 400;
  src:
    url('./HindRegular.woff2') format('woff2'),
    url('./HindRegular.woff') format('woff');
}

@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 600;
  src:
    url('./HindSemibold.woff2') format('woff2'),
    url('./HindSemibold.woff') format('woff');
}
