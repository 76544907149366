@keyframes placeHolderFade {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
}

@keyframes placeHolderFadeWithBgColor {
  0% {
    background-color: var(--color_placeholder-bg-1);
  }

  50% {
    background-color: var(--color_placeholder-bg-2);
  }

  100% {
    background-color: var(--color_placeholder-bg-1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes landDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -25%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity-50 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes fadeIn {
  0% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: visible;
    opacity: 0;
  }
}

@define-mixin placeHolderShimmer {
  animation-name: placeHolderShimmer;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@define-mixin placeHolderFade {
  animation-name: placeHolderFade;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@define-mixin placeHolderFadeWithBgColor {
  animation-name: placeHolderFadeWithBgColor;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: 2;
  animation-fill-mode: both;
}

@define-mixin fadeInAnimation {
  visibility: hidden;
  animation: 1s fadeIn forwards;
}

@define-mixin longFadeInAnimation {
  visibility: hidden;
  animation: 2s fadeIn forwards;
}

@define-mixin fadeOutAnimation {
  visibility: visible;
  animation: 1s fadeOut forwards;
}

@define-mixin longFadeOutAnimation {
  visibility: visible;
  animation: 2s fadeOut forwards;
}
